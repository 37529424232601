import React from "react";
import { Dimensions, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
// import { ActivityIndicator, Dimensions, WebView } from "react-native";

const printIframe = id => {
  const iframe = document.frames
    ? document.frames[id]
    : document.getElementById(id);
  const iframeWindow = iframe.contentWindow || iframe;

  iframe.focus();
  iframeWindow.print();

  return false;
};

export default class PdfViewer extends React.Component {
  // state = {
  //   numPages: null,
  //   pageNumber: 1
  // };

  // onDocumentLoadSuccess = ({ numPages }) => {
  //   this.setState({ numPages });
  // };

  state = {
    loading: true
  };

  handleMessage = this.handleMessage;

  componentWillMount() {
    window.addEventListener("message", this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage);
  }

  handleMessage(event) {
    if (event.data.action === "receipt-loaded") {
      this.setState({
        isLoading: false
      });
    }
  }

  componentDidMount() {
    this.refs.iframe.addEventListener("load", () =>
      this.setState({ loading: false })
    );

    // setTimeout(() => {
    //   if (!this.state.loading) {
    //     this.forceUpdate();
    //   }
    // }, 7000);
  }

  get isDesktop() {
    let width = Dimensions.get("window").width;

    if (width <= 1024) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    // const { pageNumber, numPages } = this.state;
    let { isPrint } = this.props;

    var uri = "http:" + this.props.data;

    if (/\.pdf$/.test(uri)) {
      uri = isPrint
        ? uri
        : `https://drive.google.com/viewerng/viewer?embedded=true&url=${uri}`;
    }

    return (
      <>
        <iframe
          ref="iframe"
          src={uri}
          style={{
            position: "fixed",
            top: this.isDesktop ? 90 : 50,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: `calc(100% - ${this.isDesktop ? "90px" : "50px"})`,
            border: 0,
            margin: 0,
            padding: 0,
            overflow: "hidden",
            backgroundColor: "white",
            zIndex: 999999,
            ...(this.props.isPrint ? { top: 0 } : {}),
            height: this.props.isPrint
              ? `100%`
              : `calc(100% - ${this.isDesktop ? "90px" : "50px"})`
          }}
        />
        {this.state.loading ? (
          <View
            style={{
              position: "fixed",
              top: this.isDesktop ? 90 : 50,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: `calc(100% - ${this.isDesktop ? "90px" : "50px"})`,
              border: 0,
              margin: 0,
              padding: 0,
              overflow: "hidden",
              backgroundColor: "white",
              zIndex: 9999999,
              ...(this.props.isPrint ? { top: 0 } : {}),
              height: this.props.isPrint
                ? `100%`
                : `calc(100% - ${this.isDesktop ? "90px" : "50px"})`
            }}
          >
            <View
              style={{
                flex: 1,
                background: "white",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <ActivityIndicator size={40} color="black" />
            </View>
          </View>
        ) : null}
      </>
    );
  }
}
