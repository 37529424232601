import React from "react";
import styled, { css } from "styled-components/native";
import styledMap, { mapToTheme as theme } from "styled-map";
import { FlatList } from "react-native";

export const Wrapper = styled.View`
  margin-bottom: 5;
  background: white;
  ${({ theme, isPrint }) =>
    theme.isDesktop(css`
      background: transparent;
      margin-bottom: 30;
      max-width: ${!isPrint ? "1024px" : "750px"};
      min-width: ${!isPrint ? "1024px" : "750px"};
      align-self: center;
    `)}
`;

export const WrapperInner = styled(Wrapper)``;
