import React from "react";
import { View, TouchableOpacity } from "react-native";
import { MaterialIcons as Icon } from "@expo/vector-icons";
import { withTheme } from "styled-components/native";
import { css } from "styled-components";

import MenuItem from "./MenuItem";

class Menu extends React.Component {
  render() {
    let { theme, handleToggle, isPrint, current } = this.props;
    return (
      <>
        <View
          css={`
            border: 0.6px solid black;
            ${theme.bp.desktop(css`
              border: 0.6px solid ${theme.color.softBlack};
              display: ${!isPrint ? "auto" : "none"};
            `)}
          `}
        />
        <View
          css={css`
            padding-left: 5px;
            flex: 1;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            background-color: white;
            ${theme.bp.desktop(css`
              display: ${!isPrint ? "auto" : "none"};
              background: ${theme.color.white};
              border-radius: 0 0 6px 6px;
              box-shadow: rgba(0, 0, 0, 0.24) 0px 0.5px 0.75px;
              margin-bottom: 5px;
            `)}
          `}
        >
          <MenuItem {...this.props} type="planning" />
          <MenuItem {...this.props} type="attached" />

          {current === "planning" ? (
            <TouchableOpacity
              onPress={handleToggle}
              style={{ marginLeft: "auto", paddingRight: 15 }}
            >
              <Icon name="print" color="black" size={26} />
            </TouchableOpacity>
          ) : null}

          {/* TODO: agregar icon button https://callstack.github.io/react-native-paper/icon-button.html  */}
          {/* <Icon
            name="file-download"
            color="black"
            size={26}
            style={{ paddingRight: 15 }}
          /> */}
        </View>
      </>
    );
  }
}

export default withTheme(Menu);
