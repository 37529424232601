import React, { Component } from "react";
import {
  View,
  KeyboardAvoidingView,
  Platform,
  Dimensions,
  Image
} from "react-native";
import { SafeAreaView } from "react-navigation";
import { Formik, Field } from "formik";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import {
  Button,
  Divider,
  Text,
  TextInput,
  HelperText
} from "react-native-paper";
import startCase from "lodash.startcase";
import { Subscribe } from "unstated";
import qs from "qs";

import { RegisterField } from "./RegisterField";
import { withNavigationRouter, routeNavigation } from "../../withReactRouter";
import Notification from "../../components/Notification";
import validationSchema, {
  registerWithoutSchool,
  registerManualSchool
} from "./validation";
import logoLong from "../../../assets/logo_long.png";
import LoginContainer from "../Login/LoginContainer";
import SchoolList from "./SchoolList";
import SelectPositionDialog from "./SelectPositionDialog";
import { TouchableWithoutFeedback } from "react-native";
import SchoolAddition from "./SchoolAddition";
import { analytics } from "../../utils";

const REGISTER_MUTATION = gql`
  mutation register($user: UserInput) {
    register(user: $user) {
      fullName
      email
      accountType
    }
  }
`;

class Register extends Component {
  // TODO: add next focus on inputs
  // https://stackoverflow.com/questions/32748718/react-native-how-to-select-the-next-textinput-after-pressing-the-next-keyboar

  constructor() {
    super();
    this._notificationRef = React.createRef();
    this._schoolListRef = React.createRef();
  }

  componentDidMount() {
    analytics.pageHit("Register");
  }

  // static contextType = schoolListContext;

  state = {
    schools: [],
    toggleDialog: false,
    position: "",
    schoolNotFound: false,
    loading: false
  };

  setToggleDialog = () => {
    this.setState({ toggleDialog: !this.state.toggleDialog });
  };

  setToggleSchoolNotFound = () => {
    this.setState({ schoolNotFound: !this.state.schoolNotFound });
  };

  setPosition = setFieldValue => {
    return position => {
      setFieldValue("position", position);
      this.setState({ position: position });
    };
  };

  get isDesktop() {
    let width = Dimensions.get("window").width;

    if (width <= 1024) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    let { schoolNotFound, loading } = this.state;
    let { history: historyRouter } = this.props;

    return (
      <SafeAreaView
        style={{
          flex: 1,
          justifyContent: "center",
          maxWidth: 500,
          ...(!this.isDesktop && Platform.OS === "ios"
            ? {}
            : {
                transform: [{ translateX: "-50%" }],
                left: "50%"
              })
        }}
      >
        <Subscribe to={[LoginContainer]}>
          {login => {
            return (
              <KeyboardAvoidingView behavior="padding">
                <Formik
                  initialValues={{
                    fullName: "",
                    email: "",
                    emailConfirm: "",
                    password: "",
                    passwordConfirm: "",
                    school: "",
                    schoolObj: {},

                    // schoolNotFound
                    schoolName: "",
                    schoolRegion: "",
                    schoolComuna: "",

                    position: "",
                    ...(this.state.position === "Otro"
                      ? { positionOptional: "" }
                      : {})
                    // fullName: "rodrigo figueroa",
                    // email: "test@test.com",
                    // emailConfirm: "rodrigo@rodrigo.com",
                    // password: "unouno",
                    // passwordConfirm: "unouno"
                  }}
                  validationSchema={
                    this.state.position === "Otro"
                      ? registerWithoutSchool
                      : this.state.schoolNotFound
                      ? registerManualSchool
                      : validationSchema
                  }
                  onSubmit={async values => {
                    //TODO: wesbos vid about composability to remove try catch from this await
                    let { passwordConfirm, emailConfirm, ...user } = values;
                    var label = { campaign: "organic" };

                    if (this.props.history.location.search) {
                      label = qs.parse(
                        this.props.history.location.search.substr(
                          1,
                          this.props.history.location.search.length
                        )
                      );
                    }

                    this.setState({ loading: true });
                    try {
                      let result = await this.props.client.mutate({
                        mutation: REGISTER_MUTATION,
                        variables: {
                          user
                        }
                      });

                      analytics.event({
                        category: "Registro",
                        action: "Registro Exitoso",
                        label: label.campaign,
                        value: JSON.stringify(user)
                      });

                      login.setGrantedRegister({
                        push: routeNavigation(this.props).push,
                        user: result.data.register
                      });
                      this.setState({ loading: false });
                    } catch (e) {
                      console.log("error => en on submit", e);

                      this.setState({ loading: false });
                      if (
                        e.toString().includes("email ya esta registrado") ||
                        e.toString().includes("expected `email` to be unique")
                      ) {
                        return this._notificationRef.current.showNotification(
                          "Error: Email ya esta registrado"
                        );
                      }
                      this._notificationRef.current.showNotification();
                    }
                  }}
                >
                  {({
                    values,
                    handleSubmit,
                    handleBlur,
                    setFieldTouched,
                    setFieldValue,
                    isSubmitting,
                    touched,
                    errors
                  }) => (
                    <View
                      style={{
                        padding: 10,
                        height: "100%",
                        justifyContent: "center"
                      }}
                    >
                      <Notification ref={this._notificationRef}>
                        Registro Incorrecto
                      </Notification>

                      <Image
                        style={{
                          alignSelf: "center",
                          marginBottom: 20,
                          opacity: 1,
                          width: 160,
                          height: 90,
                          resizeMode: "contain"
                        }}
                        source={logoLong}
                      />

                      <Field
                        name="fullName"
                        testID="fullname"
                        label="Nombre Completo"
                        autoCapitalize="none"
                        component={RegisterField}
                      />

                      <TouchableWithoutFeedback
                        onPress={() => {
                          this.setToggleDialog();
                        }}
                      >
                        <TextInput
                          testID="cargo"
                          label="Cargo"
                          value={values.position}
                          onBlur={() => setFieldTouched("position")}
                        />
                      </TouchableWithoutFeedback>
                      <HelperText
                        type="error"
                        visible={
                          touched.position && errors.position ? true : false
                        }
                        style={
                          touched.position && errors.position
                            ? { paddingBottom: 10 }
                            : {
                                height: 0,
                                maxHeight: 10
                              }
                        }
                      >
                        {errors.fullName}
                      </HelperText>
                      <SelectPositionDialog
                        toggleDialog={this.state.toggleDialog}
                        setToggleDialog={this.setToggleDialog}
                        setPosition={position =>
                          this.setPosition(setFieldValue)(position)
                        }
                      />

                      {values.position === "Otro" ? (
                        <Field
                          name="positionOptional"
                          testID="positionOptional"
                          autoCapitalize="none"
                          label="Institución y Cargo"
                          onChangeText={value =>
                            setFieldValue(
                              "positionOptional",
                              value.toLowerCase()
                            )
                          }
                          component={RegisterField}
                        />
                      ) : null}

                      {schoolNotFound &&
                      values.position.length &&
                      values.position !== "Otro" ? (
                        <SchoolAddition
                          setFieldValue={setFieldValue}
                          touched={touched}
                          errors={errors}
                          handleBlur={handleBlur}
                        />
                      ) : null}

                      {!schoolNotFound &&
                      values.position === "Otro" ? null : !schoolNotFound ? (
                        <View>
                          <Field
                            name="school"
                            testID="colegio"
                            label="Colegio"
                            autoCapitalize="none"
                            onChangeText={value => {
                              setFieldValue("school", value);
                              this._schoolListRef.current.search(value);
                            }}
                            onBlur={() => {
                              setFieldTouched("school");
                              Boolean(this._schoolListRef)
                                ? setTimeout(
                                    () =>
                                      this._schoolListRef &&
                                      this._schoolListRef.current &&
                                      this._schoolListRef.current.setState &&
                                      this._schoolListRef.current.setState({
                                        schools: []
                                      }),
                                    500
                                  )
                                : null;
                            }}
                            component={RegisterField}
                          />
                          <SchoolList
                            ref={this._schoolListRef}
                            schoolValue={values.school}
                            setFieldValue={setFieldValue}
                            setToggleSchoolNotFound={
                              this.setToggleSchoolNotFound
                            }
                          />
                        </View>
                      ) : null}

                      <Field
                        name="email"
                        testID="email"
                        keyboardType="email-address"
                        autoCapitalize="none"
                        label="Correo electrónico"
                        onChangeText={value =>
                          setFieldValue("email", value.toLowerCase())
                        }
                        component={RegisterField}
                      />

                      <Field
                        name="emailConfirm"
                        testID="emailconfirm"
                        keyboardType="email-address"
                        label="Confirmar correo electrónico"
                        autoCapitalize="none"
                        onChangeText={value =>
                          setFieldValue("emailConfirm", value.toLowerCase())
                        }
                        component={RegisterField}
                      />

                      <Field
                        name="password"
                        testID="password"
                        label="Contraseña"
                        secureTextEntry={true}
                        component={RegisterField}
                      />

                      <Field
                        name="passwordConfirm"
                        testID="passwordConfirm"
                        label="Confirmar contraseña"
                        secureTextEntry={true}
                        component={RegisterField}
                      />

                      <Button
                        color="#3f51b5"
                        testID="sendRegister"
                        onPress={handleSubmit}
                        style={{ padding: 3 }}
                        loading={loading}
                        disabled={loading}
                        // icon={<Icon name="checkcircle" size={15} color="white" />}
                        // iconRight

                        mode="contained"
                      >
                        Registrarse
                      </Button>

                      <Button
                        color="#3f51b5"
                        mode="outlined"
                        style={{ marginTop: 10, padding: 3 }}
                        onPress={() => {
                          Platform.OS === "ios"
                            ? routeNavigation(this.props).navigate("Login")
                            : routeNavigation(this.props).push("Login");
                        }}
                        style={{ marginTop: 10, padding: 3 }}
                        // icon={<Icon name="checkcircle" size={15} color="white" />}
                        // iconRight
                      >
                        Volver a iniciar sesión
                      </Button>
                    </View>
                  )}
                </Formik>
              </KeyboardAvoidingView>
            );
          }}
        </Subscribe>
      </SafeAreaView>
    );
  }
}

export default withApollo(withNavigationRouter(Register));
