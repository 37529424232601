import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { AsyncStorage } from "react-native";

const options = [
  "Pre-kínder a 2º básico",
  "3º básico a 6º básico",
  "7º básico a IV medio"
  // "Cancelar"
];

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  }
});

function HeaderModal(props) {
  const classes = useStyles();
  const { onClose, selectedValue, ...other } = props;

  function handleClose() {
    onClose(selectedValue);
  }

  function handleListItemClick(value) {
    onClose(value);
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      {...other}
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
    >
      <DialogTitle id="simple-dialog-title">Selecciona Nivel</DialogTitle>
      <List>
        {options.map((level, index) => (
          <ListItem
            button
            onClick={() => handleListItemClick(level)}
            key={level}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                {index === 3 ? <CloseIcon /> : <AddIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={level} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

HeaderModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.string
};

export default class HeaderModalWrapper extends React.Component {
  state = {
    open: false,
    selectedValue: options[0]
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = selectedValue => {
    this.setState({ open: false, selectedValue });
    this.props.setCurrentLevel(options.indexOf(selectedValue) + 1);

    AsyncStorage.setItem(
      "level-history",
      JSON.stringify(options.indexOf(selectedValue) + 1)
    );
  };

  render() {
    return (
      <HeaderModal
        selectedValue={this.state.selectedValue}
        open={this.state.open}
        onClose={this.handleClose}
      />
    );
  }
}
