import React, { Component } from "react";
import { Field } from "formik";
import { RegisterField } from "./RegisterField";
import regions from "./regions";
import { HelperText } from "react-native-paper";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: "flex",
//     flexWrap: "wrap"
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));

const classes = {
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    marginBottom: 10
  },
  selectEmpty: {
    marginTop: 5
  }
};

export default class SchoolAddition extends Component {
  state = {
    schoolRegion: "",
    schoolComuna: ""
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
    this.props.setFieldValue(name, event.target.value);
  };

  render() {
    let { touched, errors, handleBlur } = this.props;
    return (
      <>
        <Field
          name="schoolName"
          label="Nombre colegio"
          component={RegisterField}
        />

        <FormControl variant="filled" style={classes.formControl}>
          <InputLabel htmlFor="filled-schoolRegion-native-simple">
            Región Colegio
          </InputLabel>
          <Select
            native
            value={this.state.schoolRegion}
            onChange={this.handleChange("schoolRegion")}
            onClose={handleBlur}
            input={
              <FilledInput
                name="schoolRegion"
                id="filled-schoolRegion-native-simple"
              />
            }
          >
            {[
              <option value="" />,
              ...regions.map(({ region }, index) => {
                return <option value={region}>{region}</option>;
              })
            ]}
          </Select>
          <HelperText
            type="error"
            visible={touched.schoolRegion && errors.schoolRegion ? true : false}
            style={
              touched.schoolRegion && errors.schoolRegion
                ? { paddingBottom: 10 }
                : {
                    height: 0,
                    maxHeight: 10
                  }
            }
          >
            {errors.schoolRegion}
          </HelperText>
        </FormControl>

        {this.state.schoolRegion ? (
          <>
            <FormControl variant="filled" style={classes.formControl}>
              <InputLabel htmlFor="filled-schoolComuna-native-simple">
                Comuna Colegio
              </InputLabel>
              <Select
                native
                value={this.state.schoolComuna}
                onChange={this.handleChange("schoolComuna")}
                onClose={handleBlur}
                input={
                  <FilledInput
                    name="schoolComuna"
                    id="filled-schoolComuna-native-simple"
                  />
                }
              >
                {[
                  <option value="" />,
                  this.state.schoolRegion.length &&
                    regions
                      .filter(
                        ({ region }) => region === this.state.schoolRegion
                      )[0]
                      .comunas.map((item, index) => {
                        return <option value={item}>{item}</option>;
                      })
                ]}
              </Select>
              <HelperText
                type="error"
                visible={
                  touched.schoolComuna && errors.schoolComuna ? true : false
                }
                style={
                  touched.schoolComuna && errors.schoolComuna
                    ? { paddingBottom: 10 }
                    : {
                        height: 0,
                        maxHeight: 10
                      }
                }
              >
                {errors.schoolComuna}
              </HelperText>
            </FormControl>
          </>
        ) : null}
      </>
    );
  }
}
