import {
  MaterialIcons as Icon,
  MaterialCommunityIcons
} from "@expo/vector-icons";
import React from "react";
import { View, Text } from "react-native";
import { withTheme } from "styled-components/native";
import { css } from "styled-components";
const HeadlineIcons = ({ group, time, isPrint, theme }) => {
  return (
    <View
      css={`
        flex-direction: row;
        border-bottom: 2px solid #eee;
        min-height: 60px;
        flex: 1;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        ${theme.isDesktop(css`
          min-height: ${!isPrint ? theme.spacing.xxHuge : theme.spacing.huge}px;
        `)}
      `}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <MaterialCommunityIcons
          name="account-group"
          size={!isPrint ? 22 : 15}
          style={{ marginRight: 5 }}
        />
        <Text>{group}</Text>
      </View>
      <View style={{ width: 20 }} />
      <View
        style={{
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <MaterialCommunityIcons
          name="timer"
          size={!isPrint ? 22 : 15}
          style={{ marginRight: 5 }}
        />
        <Text>{time}</Text>
      </View>
    </View>
  );
};

export default withTheme(HeadlineIcons);
