import * as Yup from "yup";

const msg = {
  user: {
    required: "Campo usuario es obligatorio"
  },
  password: {
    min: "Contraseña debe tener al menos 4 caracteres",
    required: "Campo contraseña es obligatorio"
  }
};

export default Yup.object().shape({
  user: Yup.string()
    .max(255)
    .required(msg.user.required),
  password: Yup.string()
    .min(4, msg.password.min)
    .max(255)
    .required(msg.password.required)
});
