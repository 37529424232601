import React, { useState } from "react";
import Fab from "@material-ui/core/Fab";
import NavigationIcon from "@material-ui/icons/Navigation";
import * as Icon from "@expo/vector-icons";
import { css } from "styled-components";
import { isMobileWeb } from "../../utils";
export default function Fabb({ handleScrollButton, index }) {
  const [toggle, setToggle] = useState(true);

  return (
    <>
      {isMobileWeb ? null : (
        <div
          onClick={() => {
            handleScrollButton({ index, toggle });
            setToggle(prev => {
              return !prev;
            });
          }}
          css={css`
            position: absolute;
            top: calc(50% - 20px);
            ${!toggle ? "left" : "right"}: -28px;
            transform: translateY(-50%) scale(0.6);
            z-index: 1;
          `}
        >
          <Fab color="primary">
            <Icon.AntDesign
              name={!toggle ? "left" : "right"}
              color="white"
              size={24}
            />
          </Fab>
        </div>
      )}
    </>
  );
}
