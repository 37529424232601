import React from "react";
import { createStackNavigator } from "react-navigation";
import DownloadsScreen from "../screens/Downloads";
import { Ionicons } from "@expo/vector-icons";

const StackDownload = createStackNavigator(
  {
    Download: {
      screen: DownloadsScreen,
      navigationOptions: ({ navigation }) => {
        return {
          headerTitle: "Descargas",
          headerLeft: (
            <Ionicons
              name="md-menu"
              onPress={() => navigation.openDrawer()}
              style={{ paddingLeft: 10 }}
              size={30}
            />
          )
        };
      }
    }
  },
  {
    defaultNavigationOptions: {
      gesturesEnabled: false
    }
  }
);

export default StackDownload;
