//faqmodal
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";
import { css } from "styled-components";
import gql from "graphql-tag";
import { Dimensions, View, TouchableOpacity, StyleSheet } from "react-native";
import { useMutation } from "react-apollo";
import { TextInputMask } from "react-native-masked-text";
import * as Icon from "@expo/vector-icons";

import LoginContainer from "../screens/Login/LoginContainer";
import { isMobileWeb } from "../utils";
import {
  TextInput,
  HelperText,
  Title,
  Paragraph,
  Caption,
  Divider
} from "react-native-paper";
import Colors from "../constants/Colors";

const FaqModal = React.forwardRef(props => {
  const { handleClose, selectedValue, open, isVisit, ...other } = props;

  return (
    <Subscribe to={[LoginContainer]}>
      {login => {
        let isLoggedIn = Boolean(
          Object.keys(JSON.parse(localStorage.getItem("user"))).length
        );

        return (
          <Dialog
            onClose={() => {
              handleClose();
            }}
            aria-labelledby="simple-dialog-title"
            open={open}
            {...other}
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <TouchableOpacity onPress={handleClose}>
              <View
                style={{
                  position: "fixed",
                  margin: 16,
                  right: 10,
                  top: 10,
                  zIndex: 99999
                }}
              >
                <Icon.MaterialIcons name="close" color="white" size={33} />
              </View>
            </TouchableOpacity>

            <List>
              <div
                style={{
                  maxWidth: isMobileWeb
                    ? Dimensions.get("window").width - 5
                    : 600
                }}
              >
                <div
                  css={css`
                    background: deepskyblue;
                    height: 80px;
                    top: -8px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <h3
                    css={css`
                      color: white;
                      font-family: arial;
                      font-size: 24px;
                      position: relative;
                      padding: 0 10px;
                    `}
                  >
                    Preguntas Frecuentes
                  </h3>
                </div>
                <div
                  css={css`
                    margin: 19px 30px;
                    color: black;
                    font-family: arial;
                    font-size: 18px;
                    padding: ${isMobileWeb ? "0px" : "inherit"};
                    display: flex;
                    flex-direction: column;
                    li {
                      margin-bottom: 5px;
                    }
                  `}
                >
                  <Caption
                    style={{
                      alignItems: "center",
                      textAlign: "center",
                      marginBottom: 20,
                      fontSize: 16,
                      background: "#eee",
                      padding: 12
                    }}
                  >
                    Invitamos a todos los facilitadores (docentes y
                    profesionales de la educación) a apoyarse en las siguientes
                    “preguntas frecuentes”, para implementar las sesiones de la
                    plataforma.{" "}
                  </Caption>

                  <Title style={styles.title}>
                    {" "}
                    ¿Qué es la educación emocional?
                  </Title>
                  <Paragraph style={styles.italic}>
                    Todos los contenidos que trabajamos en EducaSwitch tienen
                    respaldo científico en diversas investigaciones de Educación
                    Emocional. Te invitamos a ver el siguiente video para
                    conocer más sobre este tema.
                  </Paragraph>
                  <iframe
                    src="https://player.vimeo.com/video/355603560"
                    width="100%"
                    height="360"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  ></iframe>

                  <Divider style={styles.divider} />

                  <Title style={styles.title}>
                    {" "}
                    ¿En qué consiste la plataforma?
                  </Title>
                  <Paragraph style={styles.p}>
                    EducaSwitch ha creado esta plataforma con más de 100 videos
                    tutoriales, para que los facilitadores (docentes), puedan
                    realizar sesiones de prevención de violencia (bullying,
                    ciberacoso, etc) y promoción de sana convivencia (vínculo,
                    emociones, etc).
                  </Paragraph>
                  <Paragraph style={styles.p}>
                    Cada video tutorial viene con una planificación y anexos
                    (cuentos, audios, imágenes, etc) que ayudan a realizar las
                    sesiones, las cuales tienen una duración de 45 minutos
                    aprox.
                  </Paragraph>
                  <Paragraph style={styles.p}>
                    Para cada contenido, existen sesiones diferenciadas por
                    niveles, según las etapas de desarrollo de los estudiantes:
                  </Paragraph>
                  <Paragraph>- Nivel 1: Pre-kínder a 2º básico</Paragraph>
                  <Paragraph>
                    - Nivel 2: 3º básico a 6º básico Nivel 3: 7º básico a IV
                    medio
                  </Paragraph>
                  <Paragraph style={{ marginBottom: 10 }}>
                    - Nivel 3: 7º básico a IV medio
                  </Paragraph>

                  <Divider style={styles.divider} />

                  <Title style={styles.title}>
                    {" "}
                    ¿Qué actividades y metodologías se proponen?
                  </Title>
                  <Paragraph style={styles.p}>
                    Todas las actividades propuestas han sido creadas en base a
                    metodologías experienciales, en donde los estudiantes
                    “aprenden haciendo”, ya que según CASEL (institución líder
                    mundial en educación emocional), las habilidades personales
                    y sociales se aprenden principalmente practicándolas.
                  </Paragraph>
                  <Paragraph style={styles.p}>
                    Por esto, las sesiones han sido creadas a partir de:
                  </Paragraph>
                  <Paragraph>- Juegos y dinámicas grupales</Paragraph>
                  <Paragraph>- Juego de roles y pedagogía teatral</Paragraph>
                  <Paragraph>- Imaginería y mindfulness</Paragraph>
                  <Paragraph>- Bailes y coreografías</Paragraph>
                  <Paragraph>- Reflexiones orientadas a la acción</Paragraph>

                  <Paragraph style={styles.pt}>
                    El facilitador puede cambiar o adaptar las actividades y
                    metodologías, según las características de su curso y el
                    tiempo que disponga.
                  </Paragraph>

                  <Divider style={styles.divider} />

                  <Title style={styles.title}>
                    ¿Cómo se debería preparar un facilitador (docente) para
                    enfrentar las temáticas de bullying, conflictos y riesgos en
                    internet, previo a las sesiones?
                  </Title>
                  <Paragraph style={styles.p}>
                    Antes de realizar las sesiones, el facilitador debería ver
                    los videos tutoriales y leer las planificaciones y anexos.
                    En caso de alguna duda pueden escribirnos en la sección
                    contacto.
                  </Paragraph>
                  <Paragraph style={styles.p}>
                    Una vez revisadas las planificaciones, se sugiere averiguar
                    si existen casos de acoso escolar, sexting, grooming u otro
                    conflicto dentro del curso. Es relevante saber quiénes han
                    estado involucrado, y en qué estado emocional se encuentran
                    las víctimas. Esto le podría ayudar al facilitador para
                    enfrentar las sesiones con mayor seguridad, visualizando
                    probables desafíos que se puedan presentar durante las
                    actividades. En caso que lo considere pertinente, puede
                    realizar cambios en las dinámicas sugeridas con el fin de
                    cuidar y proteger a los estudiantes.
                  </Paragraph>
                  <Paragraph style={styles.p}>
                    Es muy importante que el facilitador conozca el manual de
                    Convivencia Escolar de su establecimiento, para que en caso
                    de que se devele una situación de acoso escolar, pueda
                    realizar las acciones sugeridas en dicho manual.
                  </Paragraph>
                  <Paragraph style={styles.p}>
                    Por último, sugerimos que previo o durante las sesiones, se
                    presente al curso el “Encargado de Convivencia Escolar” del
                    establecimiento, así los estudiantes sabrán a quién recurrir
                    en caso que sean testigos o víctimas de acoso escolar u otro
                    conflicto.
                  </Paragraph>

                  <Divider style={styles.divider} />

                  <Title style={styles.title}>
                    ¿Qué debería hacer el facilitador si se devela una situación
                    de acoso escolar u otro conflicto durante las sesiones?
                  </Title>
                  <Paragraph style={styles.p}>
                    Se sugiere acercarse a las víctimas para validar y contener
                    su emoción. Si la víctima se encuentra muy afectada por la
                    situación, puede solicitar apoyo de algún profesional para
                    que la contenga fuera de la sala.
                  </Paragraph>
                  <Paragraph style={styles.p}>
                    En caso que sea necesario, puede detener la actividad para
                    abordar la situación, comprometiéndose frente al curso a
                    intentar detener el acoso escolar o el conflicto en
                    cuestión. Luego, empodere e invite a los estudiantes a
                    denunciar situaciones de acoso escolar. Para que esto ocurra
                    es de suma relevancia que se muestre abierto, disponible y
                    cercano, generando la confianza necesaria para que los
                    estudiantes lo sientan como una figura de apoyo.
                  </Paragraph>
                  <Paragraph style={styles.p}>
                    Finalmente, se sugiere no aplicar ninguna sanción hasta no
                    contar con toda la información necesaria.
                  </Paragraph>

                  <Divider style={styles.divider} />
                  <Title style={styles.title}>
                    ¿Qué acciones debería realizar el facilitador para detener
                    una situación de acoso en el curso?
                  </Title>
                  <Paragraph style={styles.p}>
                    Luego de verificar que la víctima esté fuera de peligro, se
                    sugiere:
                  </Paragraph>
                  <Paragraph>
                    - Compartir la información con el equipo de convivencia
                    escolar para adherirse a los protocolos establecidos en su
                    establecimiento. Además, se sugiere pedir apoyo a la dupla
                    psicosocial y a otros profesionales del establecimiento
                    (inspectores, jefe UTP, etc).
                  </Paragraph>

                  <Paragraph>
                    - Reunirse con cada uno de los involucrados por separado
                    (víctimas, victimarios y testigos), para tener una
                    conversación empática y honesta, buscando obtener la mayor
                    información posible de los actos de acoso ocurridos.
                  </Paragraph>

                  <Paragraph>
                    - Informar a los apoderados de todos los involucrados sobre
                    lo que está ocurriendo y analizar posibles acciones para
                    detener esta situación.
                  </Paragraph>
                </div>
              </div>
            </List>
          </Dialog>
        );
      }}
    </Subscribe>
  );
});

const styles = StyleSheet.create({
  divider: {
    marginTop: 20,
    marginBottom: 20
  },
  pt: {
    marginTop: 10,
    marginBottom: 10
  },
  p: {
    marginBottom: 10
  },
  italic: {
    marginBottom: 10,
    fontStyle: "italic"
  },
  title: {
    color: "white",
    backgroundColor: Colors.softPrimary,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5
  }
});
export default FaqModal;
