import algolia from "algoliasearch";
import React, { useState } from "react";
import { Text } from "react-native";
import { Divider, Button } from "react-native-paper";
import startCase from "lodash.startcase";
import { Ionicons } from "@expo/vector-icons";

const client = algolia("1UBRQKR3FY", "9312b95765bf5eba754477865b336eaf");
const algoliaSearch = client.initIndex("dev_Schools");

export default class SchoolList extends React.Component {
  state = {
    schools: [],
    setFieldValue: false
  };

  search = value => {
    algoliaSearch
      .search(value)
      .then(({ hits }) => {
        this.setState({
          schools: hits
        });

        if (!Boolean(hits.length)) {
          this.setState({ setFieldValue: false });
        }
      })
      .catch(err => {
        console.log("agoliaSearch error ->", err);
      });
  };

  render() {
    let { schoolValue, setFieldValue, setToggleSchoolNotFound } = this.props;
    let { schools } = this.state;

    if (schoolValue.length === 0) return null;

    return schools.length === 0 && !this.state.setFieldValue ? (
      <Button
        color="#FFF"
        onPress={setToggleSchoolNotFound}
        style={{ color: "white", padding: 3, marginBottom: 10 }}
        mode="contained"
      >
        Agregar colegio aquí
      </Button>
    ) : (
      schools
        .map(({ NOM_RBD, RBD, NOM_COM_RBD }, index) => {
          return (
            <>
              <Text
                onPress={() => {
                  setFieldValue("schoolObj", {
                    NOM_RBD,
                    RBD,
                    NOM_COM_RBD
                  });
                  setFieldValue(
                    "school",
                    `${startCase(NOM_RBD.toLowerCase())} - ${startCase(
                      NOM_COM_RBD.toLowerCase()
                    )} - RBD: ${RBD}`
                  );
                  this.setState({ schools: [], setFieldValue: true });
                }}
                css={css`
                  text-transform: capitalize;
                  padding: 18px 15px;
                  font-size: 16px;
                  cursor: pointer;
                `}
              >{`${NOM_RBD.toLowerCase()} - ${NOM_COM_RBD.toLowerCase()} - RBD: ${RBD}`}</Text>
              {index !== 4 ? <Divider /> : null}
            </>
          );
        })
        .slice(0, 5)
    );
  }
}
