import React, { Component } from "react";
import { View, KeyboardAvoidingView, Platform, Dimensions } from "react-native";
import { SafeAreaView } from "react-navigation";
import { Formik } from "formik";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { Button, HelperText, TextInput } from "react-native-paper";
import { css } from "styled-components";
import { Subscribe } from "unstated";
import { Redirect } from "react-router-dom";

import { isDesktop, isNative } from "../../utils";
import validationSchema from "./validationProjects";
import Notification from "../../components/Notification";
import { withNavigationRouter, routeNavigation } from "../../withReactRouter";
import logoLong from "../../../assets/logo_long.png";
import { LoginImage } from "../../components/styled";
import colors from "../../constants/Colors";
import LoginContainer from "./LoginContainer";
import { LostPassword } from "../LostPassword/index.js";

class Login extends Component {
  _notificationRef = React.createRef();

  async componentWillMount() {
    let granted = JSON.parse(localStorage.getItem("granted_projects"));
    let user = JSON.parse(localStorage.getItem("user_projects"));

    if (granted) {
      routeNavigation(this.props).push("/Proyectos", user);
    }
  }

  state = {
    showReset: false
  };

  handleShowReset = () => this.setState({ showReset: !this.state.showReset });

  render() {
    let { showReset } = this.state;
    return (
      <SafeAreaView
        style={{
          flex: 1,
          justifyContent: "center",
          maxWidth: 500,
          ...(isDesktop && isNative
            ? {}
            : {
                transform: "translateX(-50%)",
                left: "50%"
              })
        }}
      >
        {!showReset && (
          <Subscribe to={[LoginContainer]}>
            {login => {
              return (
                <KeyboardAvoidingView behavior="padding">
                  <Formik
                    initialValues={{
                      user: "",
                      password: ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async values => {
                      try {
                        if (
                          values.user === "royal-american-school" &&
                          values.password === "2514"
                        ) {
                          console.log("enviado");
                          return login.setGrantedProjects({
                            push: routeNavigation(this.props).push,
                            user: {
                              name: "royal american school",
                              code: "8eIMkAsGcg"
                            }
                          });
                        }

                        if (
                          values.user === "simon-bolivar" &&
                          values.password === "1425"
                        ) {
                          return login.setGrantedProjects({
                            push: routeNavigation(this.props).push,
                            user: {
                              name: "simon bolivar",
                              code: "0PISHHM12O"
                            }
                          });
                        }

                        throw new Error("bad login");
                      } catch (e) {
                        console.log("catch", e);
                        this._notificationRef.current.showNotification();
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleSubmit,
                      setFieldTouched,
                      setFieldValue,
                      isSubmitting
                    }) => (
                      <View
                        style={{
                          padding: 10,
                          paddingTop: 0,
                          height: "100%",
                          justifyContent: "center"
                        }}
                      >
                        <Notification ref={this._notificationRef}>
                          Login Incorrecto
                        </Notification>

                        <LoginImage source={logoLong} />
                        <TextInput
                          testID="emailLogin"
                          autoCapitalize="none"
                          label="Nombre Usuario"
                          value={values.user}
                          onChangeText={value =>
                            setFieldValue("user", value.toLowerCase())
                          }
                          onBlur={() => setFieldTouched("user")}
                        />
                        <HelperText
                          type="error"
                          visible={touched.user && errors.user ? true : false}
                          style={
                            touched.user && errors.user
                              ? { paddingBottom: 10 }
                              : {
                                  height: 0,
                                  maxHeight: 10
                                }
                          }
                        >
                          {errors.user}
                        </HelperText>

                        <TextInput
                          label="Contraseña"
                          testID="passwordLogin"
                          autoCapitalize="none"
                          value={values.password}
                          secureTextEntry
                          onChangeText={value =>
                            setFieldValue("password", value)
                          }
                          onBlur={() => setFieldTouched("password")}
                        />
                        <HelperText
                          type="error"
                          visible={
                            touched.password && errors.password ? true : false
                          }
                          style={
                            touched.password && errors.password
                              ? { paddingBottom: 10 }
                              : {
                                  height: 0,
                                  maxHeight: 10
                                }
                          }
                        >
                          {errors.password}
                        </HelperText>

                        <Button
                          color={colors.primary}
                          testID="sendLogin"
                          onPress={handleSubmit}
                          mode="contained"
                          style={{ padding: 3 }}
                        >
                          Iniciar sesión
                        </Button>
                      </View>
                    )}
                  </Formik>
                </KeyboardAvoidingView>
              );
            }}
          </Subscribe>
        )}
      </SafeAreaView>
    );
  }
}

export default withApollo(withNavigationRouter(Login));
