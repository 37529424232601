export default {
  primary: "#3f51b5",
  softPrimary: "#5066E0",
  white: "#fff",
  softWhite: "rgba(255,255,255,.7)",
  darkGrey: "#333",
  // lightGrey: "#f8f8f8",
  lightGrey: "#eaeaea",
  // lightGrey: "#fafafa",
  softerGrey: "#eaeaea",
  midGrey: "rgba(0,0,0,0.4)",
  softGrey: "rgba(0, 0, 0, 0.54)",
  errorBackground: "red",
  softBlack: "rgba(0,0,0,.84)"
};
