import { Container } from "unstated";

export default class LevelContainer extends Container {
  state = {
    number: 1,
    id: "36xCm0SDLDaO2DzlcHVUM9",
    name: "Pre-kínder a 2º básico",
    levelState: []
  };

  setLevelState = levelState => this.setState({ levelState });

  setCurrentLevel = level => {
    switch (level) {
      case 1:
        this.setState({
          number: 1,
          id: "36xCm0SDLDaO2DzlcHVUM9",
          name: "Pre-kínder a 2º básico"
        });
        break;

      case 2:
        this.setState({
          number: 2,
          id: "3oAalK9uddzJ0FP3js3GwA",
          name: "3º básico a 6º básico"
        });
        break;

      case 3:
        this.setState({
          number: 3,
          id: "2caqJrBdtiAfCwSjdezmzd",
          name: "7º básico a IV medio"
        });
        break;
    }
  };
}
