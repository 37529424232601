import React, { useState, useEffect } from "react";
import { Dimensions, Text } from "react-native";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
// import { ActivityIndicator, Dimensions, WebView } from "react-native";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useMutation } from "react-apollo";
import { Snackbar } from "react-native-paper";
import { isDesktop, useIsAdmin } from "../utils";
import useReactRouter from "use-react-router";

const ADD_EMAILS_MUTATION = gql`
  mutation addEmails($schoolName: String!, $rbd: Int!) {
    addEmails(schoolName: $schoolName, rbd: $rbd) {
      status
      message
    }
  }
`;

export default function AddEmails() {
  //TODO: transformar a higher order function withAccess(). Agregar a utils o router

  const [emailsAdded, setemailsAdded] = useState(false);
  const [schoolName, setSchoolName] = useState("");
  const [rbd, setRbd] = useState("");
  const [toggleSnackbar, setToggleSnackbar] = useState(false);
  const [completed, setCompleted] = useState(false);
  const { history } = useReactRouter();
  const isAdmin = useIsAdmin();

  let [addEmails, { loading, data, error }] = useMutation(ADD_EMAILS_MUTATION, {
    onCompleted() {
      setCompleted(true);
    }
  });

  useEffect(() => {
    if (!isAdmin && isAdmin !== null) {
      history.push("/");
    }
  }, [isAdmin]);

  useEffect(() => {
    if (error) {
      setToggleSnackbar(!toggleSnackbar);
    }
  }, [error]);

  useEffect(() => {
    if (completed) {
      setTimeout(() => {
        setRbd("");
        setSchoolName("");
        setCompleted(false);
      }, 2000);
    }
  }, [completed]);

  let sendForm = () => {
    if (!schoolName || !rbd) {
      return setToggleSnackbar(!toggleSnackbar);
    }

    addEmails({
      variables: {
        schoolName,
        rbd: Number(rbd)
      }
    });
  };
  // let uri = `https://docs.google.com/spreadsheets/d/1zhVwZtVpCnHQgV4zGcfwU2S-vZrM5P9Tz8v2Za29PP8/edit?usp=sharing`;
  let uri =
    "https://docs.google.com/spreadsheets/d/14uxuVOKUbPP073AFtyWg1l108ibW9sxm3vPEEFNDv1c/edit?usp=sharing";

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingBottom: 4,
          borderBottom: "1px solid lightGray"
        }}
      >
        <TextField
          id="outlined-name"
          label="Nombre Colegio"
          value={schoolName}
          onChange={e => setSchoolName(e.target.value)}
          margin="dense"
          variant="outlined"
          style={{ marginLeft: 20, marginRight: 10 }}
        />
        <TextField
          id="outlined-name"
          label="RBD"
          value={rbd}
          onChange={e => setRbd(e.target.value)}
          margin="dense"
          variant="outlined"
          type="number"
        />
        <Button
          style={{
            marginLeft: 10,
            minHeight: 35,
            marginTop: 3,
            background: "deepskyblue",
            color: "white"
          }}
          size="medium"
          variant="contained"
          onClick={sendForm}
        >
          Agregar Colegio
        </Button>
        <iframe
          src={uri}
          style={{
            position: "absolute",
            top: 59,
            left: 0,
            bottom: 0,
            right: 0,
            width: "100%",
            height: `100vh`,
            border: "none",
            margin: 0,
            padding: 0,
            overflow: "hidden"
          }}
        />
      </div>

      <Snackbar
        visible={completed}
        onDismiss={() => setCompleted(false)}
        style={{
          backgroundColor: "green",
          width: Dimensions.get("window").width - 20,
          position: "fixed",
          bottom: 0,
          left: 0
        }}
        duration={3000}
      >
        <p>Colegio agregado</p>
      </Snackbar>

      {toggleSnackbar == true && (!schoolName || !rbd) && (
        <Snackbar
          visible={toggleSnackbar && !completed}
          onDismiss={() => setToggleSnackbar(!toggleSnackbar)}
          style={{
            backgroundColor: "#F31431",
            width: Dimensions.get("window").width - 20,
            position: "fixed",
            bottom: 0,
            left: 0
          }}
          duration={3000}
        >
          {(!schoolName || !rbd) && <p>Todos los campos son obligatiorios</p>}
        </Snackbar>
      )}
    </>
  );
}
