import { stripIndents, oneLine } from "common-tags";

export const sessionInfo = data => {
  return [
    {
      type: "VideoPlaceholder",
      placeholder: data.caratulaVideo.fields.file.url,
      videoUrl: data.video.fields.file
    },
    {
      type: "H1H2",
      h1: data && data.titulo,
      h2: data.objetivo.content[0].content[0].value
    }
  ];
};

let attachmentsTypes = pasos => {
  let obj = {
    type: "AttachedPreview",
    steps: []
  };

  pasos
    .filter(({ fields }) => fields.anexos)
    .forEach(p => {
      // if (p[0].fields.anexos[0].fields.contenido == null) return "nada";

      p.fields.anexos.forEach(anexo => {
        if (Object.keys(anexo.fields).includes("pdf")) {
          let data = anexo.fields.pdf.fields.file.url;
          let { titulo } = anexo.fields;

          obj.steps.push({
            title: titulo,
            icon: "picture-as-pdf",
            description: "formato Pdf",
            data
          });
        }

        if (Object.keys(anexo.fields).includes("video")) {
          let data = anexo.fields.video.fields.file.url;
          let { titulo } = anexo.fields;
          let placeholder = anexo.fields.placeholder.fields.file.url;

          obj.steps.push({
            title: titulo,
            icon: "play-arrow",
            description: "Formato audio",
            data,
            placeholder
          });
        }

        if (Object.keys(anexo.fields).includes("audio")) {
          let data = anexo.fields.audio.fields.file.url;
          let { titulo } = anexo.fields;

          obj.steps.push({
            title: titulo,
            icon: "audiotrack",
            description: "Formato audio",
            data
          });
        }

        if (Object.keys(anexo.fields).includes("galeriaDeFotos")) {
          let { titulo, galeriaDeFotos } = anexo.fields;

          obj.steps.push({
            title: titulo,
            icon: "image",
            description: "Formato imagenes",
            data: galeriaDeFotos.map(
              ({
                fields: {
                  description,
                  file: { url }
                }
              }) => ({ uri: "http:" + url, description })
            )
          });
        }

        if (Object.keys(anexo.fields).includes("pdf")) return true;

        if (Object.keys(anexo.fields).includes("contenido")) {
          let data = anexo.fields.contenido.content.reduce(
            (prevValue, currentValue) => {
              if (currentValue.content[0].marks.length) {
                return [
                  ...prevValue,
                  [
                    currentValue.content[0].value,
                    currentValue.content[0].marks.map(item => item.type),
                    currentValue.content[1] ? currentValue.content[1].value : ""
                  ]
                ];
              }
              return [...prevValue, currentValue.content[0].value];
            },
            []
          );

          obj.steps.push({
            title: "cuento feliz",
            icon: "short-text",
            description: "Formato texto",
            data
          });
        }
      });
    });
  return obj;
};

export const attachments = data => {
  return data.etapas
    .map(etapa => etapa.fields)
    .map(({ titulo, pasos }, index) => {
      let hasLink = pasos.some(paso => {
        return paso.fields.descripcionPaso.content[0].content.some(
          item => item.nodeType === "entry-hyperlink"
        );
      });

      if (hasLink) {
        return [
          { type: "Headline", h1: `Etapa ${index + 1}: ${titulo}` },
          attachmentsTypes(pasos)
        ];
      }
      return [attachmentsTypes(pasos)];
    })
    .reduce((prevVal, currVal) => {
      return [...prevVal, ...currVal];
    }, []);
};

const attachmentObjectGenerator = anexo => {

  if (typeof anexo === "string" && anexo.includes("form")) {
    let data = anexo
    // let { titulo } = anexo;

    return JSON.stringify({
      title: 'este es mi titulo',
      icon: "uri",
      description: "uri",
      data: anexo
    });
  }

  if (Object.keys(anexo).includes("pdf")) {
    let data = anexo.pdf.fields.file.url;
    let { titulo } = anexo;

    return JSON.stringify({
      title: titulo,
      icon: "picture-as-pdf",
      description: "formato Pdf",
      data
    });
  }

  if (Object.keys(anexo).includes("video")) {
    let data = anexo.video.fields.file.url;
    let { titulo } = anexo;
    let placeholder =
      (anexo.placeholder && anexo.placeholder.fields.file.url) ||
      "//i.imgur.com/LpqH1zh.png";

    return JSON.stringify({
      title: titulo,
      icon: "play-arrow",
      description: "Formato video",
      data,
      placeholder
    });
  }

  if (Object.keys(anexo).includes("audio")) {
    let data = anexo.audio.fields.file.url;
    let { titulo } = anexo;

    return JSON.stringify({
      title: titulo,
      icon: "audiotrack",
      description: "Formato audio",
      data
    });
  }

  if (Object.keys(anexo).includes("galeriaDeFotos")) {
    let { titulo, galeriaDeFotos } = anexo;

    return JSON.stringify({
      title: titulo,
      icon: "image",
      description: "Formato imagenes",
      data: galeriaDeFotos.map(
        ({
          fields: {
            description,
            file: { url }
          }
        }) => ({ uri: "http:" + url, description })
      )
    });
  }

  if (Object.keys(anexo).includes("contenido")) {
    let data = anexo.contenido.content.reduce((prevValue, currentValue) => {
      if (currentValue.content[0].marks.length) {
        return [
          ...prevValue,
          [
            currentValue.content[0].value,
            currentValue.content[0].marks.map(item => item.type),
            currentValue.content[1] ? currentValue.content[1].value : ""
          ]
        ];
      }
      return [...prevValue, currentValue.content[0].value];
    }, []);

    return JSON.stringify({
      title: "Cuento",
      icon: "short-text",
      description: "Formato texto",
      data
    });
  }
};

export const planning = ({ etapas, isApoderados }) => {
  if (etapas.isIntro) {
    return [];
  }

  return etapas.etapas
    .map(
      (
        {
          fields: {
            grupo = "",
            tiempo = "",
            titulo = "",
            pasos = [],
            sugerencias = ""
          } = {}
        },
        index
      ) => {
        let steps = {
          type: "Step",
          steps: (() => {
            let p = pasos.map(
              ({
                fields: {
                  descripcionPaso: { content = {} } = {},
                  anexos = []
                } = {}
              }) => {
                return content[0].content.reduce(
                  (previousValue, currentValue, index) => {
                    // if (currentValue.nodeType === "entry-hyperlink" && currentValue.data.uri.includes('form')) {
                    // return oneLine`${previousValue} ${currentValue.value}`;
                    // }
                    if (
                      currentValue.nodeType.includes("hyperlink") &&
                      (Boolean(anexos.length) || currentValue.data.uri)
                    ) {
                      return oneLine`${previousValue} <${
                        // texto del link
                        currentValue.content[0].value
                        //
                        } attachmentObject=${attachmentObjectGenerator(
                          currentValue.data.uri || currentValue.data.target.fields
                        )}>`;
                    }
                    return oneLine`${previousValue} ${currentValue.value}`;
                  },
                  ""
                );
              }
            );
            return p;
          })()
        };

        return [
          !isApoderados
            ? { type: "Headline", h1: `Etapa ${index + 1}: ${titulo}` }
            : {},
          !isApoderados
            ? {
              type: "HeadlineIcons",
              group: grupo,
              time: tiempo + " min"
            }
            : {},
          steps,
          ...(Boolean(sugerencias)
            ? [
              {
                type: "Suggestion",
                steps: sugerencias.content[0].content[0].value
              }
            ]
            : [])
        ];
      }
    )
    .reduce((prevVal, currVal) => {
      return [...prevVal, ...currVal];
    }, []);
};
