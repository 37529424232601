import React from "react";
import { View, ScrollView } from "react-native";
import { Text } from "react-native-paper";
import StyleSheet from "react-native-extended-stylesheet";
import { ParagraphText } from "../styled";

export default function ShortText({ data }) {
  return (
    <ScrollView style={styles.container}>
      {data.map(paragraph => {
        if (typeof paragraph === "string") {
          return <ParagraphText marginBottom>{paragraph}</ParagraphText>;
        }

        console.log(paragraph[2]);
        return (
          <View style={{ flexDirection: "row", marginBottom: 12 }}>
            <ParagraphText
              bold={paragraph[1].includes("bold")}
              underline={paragraph[1].includes("underline")}
              italic={paragraph[1].includes("italic")}
            >
              {paragraph[0]}
            </ParagraphText>
            {paragraph[2] && paragraph[2].length ? (
              <ParagraphText>{paragraph[2]}</ParagraphText>
            ) : null}
          </View>
        );
      })}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingHorizontal: 15
  },
  "@media (min-width: 1024)": {
    container: {
      paddingTop: 30,
      width: "$desktop.maxWidth",
      backgroundColor: "white",
      margin: "auto"
    }
  }
});
