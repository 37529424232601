import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";
import { css } from "styled-components";
import gql from "graphql-tag";
import { Dimensions, View, TouchableOpacity } from "react-native";
import { useMutation } from "react-apollo";
import { TextInputMask } from "react-native-masked-text";
import * as Icon from "@expo/vector-icons";

import LoginContainer from "../screens/Login/LoginContainer";
import { isMobileWeb } from "../utils";
import { TextInput, HelperText } from "react-native-paper";

const CONTACT_MUTATION = gql`
  mutation contact($user: UserClientInput!) {
    contact(user: $user)
  }
`;

const ContactModal = React.forwardRef((props, ref) => {
  const { handleClose, selectedValue, open, isVisit, ...other } = props;

  const [contact, { data, loading }] = useMutation(CONTACT_MUTATION, {
    onCompleted: () => {
      setError(false);
      setSent(true);
      setMessage("");
      setTouched(false);
    }
  });
  const [message, setMessage] = useState("");
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (message.length === 0) {
      setError(false);
      setTouched(false);
    }
  }, [message]);

  function buttonDisplay(loading, sent) {
    if (loading) return "...enviando";

    if (sent) return "Mensaje enviado";

    return "Enviar";

    // if (withQuote) {
    //   return "Enviado";
    // } else {
    //   return "Solicitar cotización";
    // }
  }

  const isDemo = (() => {
    return JSON.parse(localStorage.getItem("user")).accountType === "demo";
  })();

  return (
    <Subscribe to={[LoginContainer]}>
      {login => {
        let isLoggedIn = Boolean(
          Object.keys(JSON.parse(localStorage.getItem("user"))).length
        );

        return (
          <Dialog
            onClose={() => {
              handleClose();
              setSent(false);
            }}
            aria-labelledby="simple-dialog-title"
            open={open}
            {...other}
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <TouchableOpacity onPress={handleClose}>
              <View
                style={{
                  position: "fixed",
                  margin: 16,
                  right: 10,
                  top: 10,
                  zIndex: 99999
                }}
              >
                <Icon.MaterialIcons name="close" color="white" size={33} />
              </View>
            </TouchableOpacity>

            <List>
              <div
                style={{
                  maxWidth: isMobileWeb
                    ? Dimensions.get("window").width - 5
                    : 600
                }}
              >
                <div
                  css={css`
                    background: deepskyblue;
                    height: 80px;
                    top: -8px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <h3
                    css={css`
                      color: white;
                      font-family: arial;
                      font-size: 24px;
                      position: relative;
                      padding: 0 10px;
                    `}
                  >
                    Contacto
                  </h3>
                </div>
                <ul
                  css={css`
                    margin: 19px 30px;
                    color: black;
                    font-family: arial;
                    font-size: 18px;
                    padding: ${isMobileWeb ? "0px" : "inherit"};
                    min-width: ${isMobileWeb ? "100%" : "500px"};
                    li {
                      margin-bottom: 5px;
                    }
                  `}
                ></ul>
                <div
                  css={css`
                    margin: 19px 50px;
                  `}
                >
                  {isLoggedIn ? (
                    <>
                      {
                        <View>
                          <TextInput
                            multiline
                            label="Mensaje"
                            value={message}
                            onChangeText={setMessage}
                            style={{
                              minHeight: 150
                            }}
                            onBlur={() => {
                              setTouched(true);
                              if (message.length < 20) {
                                setError(true);
                              }
                            }}
                          />

                          <HelperText
                            type="error"
                            visible={touched && error ? true : false}
                            style={
                              touched && error
                                ? { paddingBottom: 10, minWidth: "100%" }
                                : {
                                    minWidth: "100%",
                                    height: 0,
                                    maxHeight: 10
                                  }
                            }
                          >
                            Campo obligatorio
                          </HelperText>
                        </View>
                      }
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          minWidth: "100%",
                          minHeight: "45px",
                          fontSize: "16px"
                        }}
                        disabled={loading || sent}
                        onClick={async () => {
                          let user = JSON.parse(localStorage.getItem("user"));
                          if (message.length < 1) {
                            setTouched(true);
                            setError(true);
                            return;
                          }
                          await contact({
                            variables: {
                              user: { ...user, message }
                            }
                          });
                        }}
                      >
                        {buttonDisplay(loading, sent)}
                      </Button>
                    </>
                  ) : (
                    <Link to="/Register" style={{ textDecoration: "none" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          minWidth: "100%",
                          minHeight: "45px",
                          fontSize: "16px"
                        }}
                      >
                        Registrarse
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </List>
          </Dialog>
        );
      }}
    </Subscribe>
  );
});

export default ContactModal;
