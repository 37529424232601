import React from "react";
import { createBottomTabNavigator } from "react-navigation";
import { Feather } from "@expo/vector-icons";
import StackHome from "./StackHome";
import StackDownload from "./StackDownload";
import { Text } from "react-native";

const TabNavigator = createBottomTabNavigator(
  {
    Home: {
      screen: StackHome
    },
    Downloads: {
      screen: StackDownload
    }
  },
  {
    navigationOptions: {
      header: null
    },
    defaultNavigationOptions: ({
      navigation: {
        state: { routeName }
      }
    }) => {
      return {
        ...{
          Downloads: {
            tabBarLabel: "Descargas",
            tabBarIcon: ({ focused, tintColor }) => (
              <Feather name="download" color={tintColor} size={27} />
            )
          },
          Home: {
            tabBarLabel: "Inicio",
            tabBarIcon: ({ focused, tintColor }) => (
              <Feather name="home" color={tintColor} size={27} />
            )
          }
        }[routeName],
        tabBarOptions: {
          activeTintColor: "black",
          inactiveTintColor: "gray",
          indicatorStyle: {
            backgroundColor: "black"
          },
          upperCaseLabel: false,
          labelStyle: {
            fontWeight: "500",
            fontSize: 11
          },
          style: {
            backgroundColor: "white",
            padding: 4,
            height: 52
          }
        }
      };
    }
  }
);

export default TabNavigator;
