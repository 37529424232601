import React from "react";
import { View, Text, Platform, Dimensions, SafeAreaView } from "react-native";
import { createAppContainer } from "react-navigation";
import {
  Provider as PaperProvider,
  ActivityIndicator
} from "react-native-paper";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { Provider as UnstatedProvider, Subscribe } from "unstated";
import StyleSheet from "react-native-extended-stylesheet";
import { ThemeProvider as ThemeProviderMaterial } from "@material-ui/styles";
// import ThemeProvider from "styled-native-breakpoint-for-web";
import ThemeProvider from "./src/components/ThemeProvider";

import * as Icon from "@expo/vector-icons";

import NavigationWeb from "./src/navigation/web";
import ApolloWrapper from "./src/components/ApolloWrapper";
import LevelContainer from "./src/components/LevelContainer";
import SwitchNavigation from "./src/navigation/SwitchNavigation";
import { AppWrapper } from "./src/components/styled";
import theme from "./src/config/theme";

import ReactGA from "react-ga";
import { isNative } from "./src/utils";

// if (!isNative) {
//   ReactGA.initialize("UA-127750939-3", { standardImplementation: true });
// }

// import Heap from "@heap/react-native-heap";
// Heap.setAppId("4277785314");

const setupWidth = () => {
  let width = Dimensions.get("window").width;

  if (width <= 1280) {
    return width;
  } else {
    return 1280;
  }
};

StyleSheet.build({
  $fontColor: "green",
  $bgColor: "#e6e6e6",
  $rem: 16,
  $destkopMQ: "@media (min-width: 700) and (max-width: 1400)",
  $test: "lime",
  $desktop: {
    maxWidth: setupWidth(),
    h1: 30,
    h2: 24,
    paragraph: 20
  }
});

const GET_LEVEL = gql`
  query getLevel($id: String!) {
    getLevel(id: $id)
  }
`;

export const DataContext = React.createContext();

console.disableYellowBox = true;

const AppContainer =
  Platform.OS == "ios" ? createAppContainer(SwitchNavigation) : NavigationWeb;
// const AppContainer = createAppContainer(SwitchNavigation);

class App extends React.Component {
  // componentDidMount() {
  //   localStorage.setItem("level-history", 1);
  // }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <UnstatedProvider>
          {/* TODO:REFACTOR: No necesito  DataContext. Puedo pasarle a LevelContainer el valor de `getLevel` habria que setear subscribe y query como HOC para pasar levelContainer al montarse y tomar el valor del query para ocuparlo en conjunto  */}
          <Subscribe to={[LevelContainer]}>
            {({ state: levelState }) => (
              <ApolloWrapper>
                <Query query={GET_LEVEL} variables={{ id: levelState.id }}>
                  {({ loading, error, data }) => {
                    if (loading)
                      return (
                        <View style={styles.loader}>
                          <ActivityIndicator size={40} color="black" />
                        </View>
                      );
                    if (error) {
                      console.log(error.message);
                      return (
                        <SafeAreaView
                          css={css`
                            flex: 1;
                            align-items: center;
                            justify-content: center;
                          `}
                        >
                          <View
                            css={css`
                              flex-direction: row;
                              align-items: center;
                            `}
                          >
                            <Icon.MaterialIcons
                              name="error-outline"
                              color="black"
                              size={22}
                              style={{ marginRight: 5 }}
                            />
                            <Text>
                              <Text
                                css={css`
                                  font-weight: bold;
                                `}
                              >
                                Hubo un error en la plataforma.{" "}
                              </Text>
                              Por favor refresca el navegador o ingresa en
                              algunos minutos más si el problema persiste.
                            </Text>
                          </View>
                        </SafeAreaView>
                      );
                    }

                    return (
                      <AppWrapper white>
                        <DataContext.Provider value={JSON.parse(data.getLevel)}>
                          <PaperProvider>
                            <ActionSheetProvider>
                              <AppContainer />
                              {/* <NavigationWeb /> */}
                            </ActionSheetProvider>
                          </PaperProvider>
                        </DataContext.Provider>
                      </AppWrapper>
                    );
                  }}
                </Query>
              </ApolloWrapper>
            )}
          </Subscribe>
        </UnstatedProvider>
      </ThemeProvider>
    );
  }
}

const styles = StyleSheet.create({
  loader: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});

export default App;
