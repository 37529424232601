import React, { Component } from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { isNative } from "../../utils";

const cache = new InMemoryCache({ addTypename: false });

const httpLink = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }),

  new HttpLink({
    // uri: "http://192.168.0.16:4000" // casa
    // uri: "http://192.168.0.195:4000" //office
    // uri: "http://172.20.10.3:4000" //iphone
    // uri: process.env.REACT_APP_API
    // uri: "https://97c368d5.ngrok.io/"
    // uri: "https://educaswitch-api-3.bidah.now.sh/"
    // uri: "https://educaswitch-api-3-1xfje8gh9.now.sh"
    // uri: "http://localhost:4000"
    // uri: "https://educaswitch-api-3-l02y7blcg.now.sh"
    // uri: "https://educaswitch-api-3.bidah.now.sh"
    // uri: "https://api-pvaun6m0q.now.sh"
    // uri: "https://educaswitch-api-3-qk24vfymq.now.sh/"
    // uri: __DEV__
    //   ? "http://localhost:4000"
    //   : "https://educaswitch-api-3-1xfje8gh9.now.sh"
    // uri: "https://educaswitch-api-3-jc3ayo0ms.now.sh"// 4/10/19 antes de almuerzo
    // uri: "https://educaswitch-api-3-557jptft3.now.sh" // 4/10/19 con envio mail masivo invite
    // uri: "https://educaswitch-api-3-3ehbbwhja.now.sh"
    // uri: "https://educaswitch-api-3-6dp6ig4ju.now.sh" // sin form nuevo con telefono
    // uri: "https://educaswitch-api-3-7098mfey8.now.sh"
    // uri: "https://educaswitch-api-3-a8pc034fi.now.sh" //cors fix
    // uri: "https://educaswitch-api-3-7098mfey8.now.sh"
    // uri: "https://educaswitch-api-3-mfzpqr132.now.sh"
    // uri: "https://educaswitch-api-3-o5eign5zr.now.sh"
    // uri: "https://educaswitch-api-3-bly2ts9hy.now.sh"

    uri: "https://educaswitch-api.herokuapp.com/"
    // uri: "http://localhost:8000"

    // uri: "https://educaswitch-api.localtunnel.me"
    // uri: "https://educaswitch-api-3-5czpndqqu.now.sh"
    // uri: "https://educaswitch-api-3-ed2ysacmk.now.sh"
    // uri: "https://educaswitch-api-3-7rp307mei.now.sh"
    // uri: "https://educaswitch-api-3-r70f75eic.now.sh/"
  })
]);

console.log("__DEV__", __DEV__);

const client = new ApolloClient({
  link: httpLink,
  cache
});

export default class ApolloWrapper extends Component {
  render() {
    return (
      <ApolloProvider client={client}>{this.props.children}</ApolloProvider>
    );
  }
}
