import React, { Component } from "react";
import {
  View,
  KeyboardAvoidingView,
  Platform,
  Dimensions,
  Image
} from "react-native";
import { SafeAreaView } from "react-navigation";
import { Formik } from "formik";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import {
  Button,
  HelperText,
  TextInput,
  Divider,
  Text
} from "react-native-paper";
import startCase from "lodash.startcase";

import { withNavigationRouter, routeNavigation } from "../../withReactRouter";
import Notification from "../../components/Notification";
import { registerWithEmail as validationSchema } from "./validation";
import logoLong from "../../../assets/logo_long.png";
import { Subscribe } from "unstated";
import LoginContainer from "../Login/LoginContainer";

const REGISTER_MUTATION = gql`
  mutation register($user: UserInput) {
    register(user: $user) {
      fullName
      email
      accountType
    }
  }
`;

const REGISTER_GET_SCHOOL_QUERY = gql`
  query getSchoolObj($email: String!) {
    getSchoolObj(email: $email) {
      NOM_RBD
      RBD
      NOM_COM_RBD
    }
  }
`;

class RegisterWithMail extends Component {
  constructor() {
    super();
    this._notificationRef = React.createRef();
  }

  async componentWillMount() {
    let { match, client } = this.props;

    let {
      data: { getSchoolObj }
    } = await client
      .query({
        query: REGISTER_GET_SCHOOL_QUERY,
        variables: {
          email: match.params.email
        }
      })
      .catch(e => {
        if (e.toString().includes("email not")) {
          return { data: { getSchoolObj: "email not found" } };
        }
      });

    if (getSchoolObj === "email not found") {
      routeNavigation(this.props).push("/Register");
    }

    this.setState({
      schoolObj: getSchoolObj,
      email: match.params.email || null
    });
  }

  state = {
    schoolObj: {
      NOM_RBD: "",
      RBD: "",
      NOM_COM_RBD: ""
    },
    email: null
  };

  get isDesktop() {
    let width = Dimensions.get("window").width;

    if (width <= 1024) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    let schoolObj = this.state;

    return this.state.loading ? null : (
      <SafeAreaView
        style={{
          flex: 1,
          justifyContent: "center",
          maxWidth: 500,
          ...(!this.isDesktop && Platform.OS === "ios"
            ? {}
            : {
                transform: "translateX(-50%)",
                left: "50%"
              })
        }}
      >
        <Subscribe to={[LoginContainer]}>
          {login => {
            return (
              <KeyboardAvoidingView behavior="padding">
                <Formik
                  initialValues={{
                    fullName: "",
                    email: this.state.email,
                    emailConfirm: this.state.email,
                    password: "",
                    passwordConfirm: "",
                    school: "test - test - RBD: 000",
                    schoolObj: this.state.schoolObj
                    // fullName: "rodrigo figueroa",
                    // email: "test@test.com",
                    // emailConfirm: "rodrigo@rodrigo.com",
                    // password: "unouno",
                    // passwordConfirm: "unouno"
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async values => {
                    //TODO: wesbos vid about composability to remove try catch from this await
                    // let { passwordConfirm, emailConfirm, ...user } = values;
                    // let { navigation } = this.props;

                    try {
                      let result = await this.props.client.mutate({
                        mutation: REGISTER_MUTATION,
                        variables: {
                          user: {
                            email: this.state.email,
                            fullName: values.fullName,
                            password: values.password,
                            school: "test - test - RDB: 000",
                            schoolObj: this.state.schoolObj
                          }
                        }
                      });

                      login.setGranted({
                        push: routeNavigation(this.props).push,
                        user: result.data.register
                      });
                    } catch (e) {
                      console.log("error => en on submit", e);

                      if (e.toString().includes("email ya esta registrado")) {
                        return this._notificationRef.current.showNotification(
                          "Error: Email ya esta registrado"
                        );
                      }
                      this._notificationRef.current.showNotification();
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    setFieldTouched,
                    setFieldValue,
                    isSubmitting
                  }) => {
                    return (
                      <View
                        style={{
                          padding: 10,
                          height: "100%",
                          justifyContent: "center"
                        }}
                      >
                        <Notification ref={this._notificationRef}>
                          Registro Incorrecto
                        </Notification>

                        <Image
                          style={{
                            alignSelf: "center",
                            marginBottom: 20,
                            opacity: 1,
                            width: 160,
                            height: 90,
                            resizeMode: "contain"
                          }}
                          source={logoLong}
                        />

                        <TextInput
                          testID="fullname"
                          label="Nombre Completo"
                          autoCapitalize="none"
                          value={values.fullName}
                          onChangeText={value =>
                            setFieldValue("fullName", value)
                          }
                          onBlur={() => setFieldTouched("fullName")}
                        />
                        <HelperText
                          type="error"
                          visible={
                            touched.fullName && errors.fullName ? true : false
                          }
                          style={
                            touched.fullName && errors.fullName
                              ? { paddingBottom: 10 }
                              : {
                                  height: 0,
                                  maxHeight: 10
                                }
                          }
                        >
                          {errors.fullName}
                        </HelperText>

                        <View />

                        <TextInput
                          testID="colegio"
                          label="Colegio"
                          autoCapitalize="none"
                          disabled={true}
                          value={`${startCase(
                            this.state.schoolObj.NOM_RBD.toLowerCase()
                          )} - ${startCase(
                            this.state.schoolObj.NOM_COM_RBD.toLowerCase()
                          )} - RBD: ${this.state.schoolObj.RBD}`}
                          onChangeText={value => {
                            setFieldValue("school", value);

                            algoliaSearch
                              .search(value)
                              .then(({ hits }) => {
                                this.setState({ schools: hits });
                              })
                              .catch(err => {
                                console.log("agoliaSearch error ->", err);
                              });
                          }}
                          onBlur={() => {
                            setFieldTouched("school");
                            setTimeout(
                              () => this.setState({ schools: [] }),
                              500
                            );
                          }}
                        />
                        <HelperText
                          type="error"
                          visible={
                            touched.school && errors.school ? true : false
                          }
                          style={
                            touched.school && errors.school
                              ? { paddingBottom: 10 }
                              : {
                                  height: 0,
                                  maxHeight: 10
                                }
                          }
                        >
                          {errors.school}
                        </HelperText>

                        <TextInput
                          testID="email"
                          keyboardType="email-address"
                          autoCapitalize="none"
                          label="email"
                          value={this.state.email}
                          disabled={true}
                          onChangeText={value =>
                            setFieldValue("email", value.toLowerCase())
                          }
                          onBlur={() => setFieldTouched("email")}
                        />
                        <HelperText
                          type="error"
                          visible={touched.email && errors.email ? true : false}
                          style={
                            touched.email && errors.email
                              ? { paddingBottom: 10 }
                              : {
                                  height: 0,
                                  maxHeight: 10
                                }
                          }
                        >
                          {errors.email}
                        </HelperText>

                        <TextInput
                          testID="password"
                          label="contraseña"
                          secureTextEntry={true}
                          value={values.password}
                          onChangeText={value =>
                            setFieldValue("password", value)
                          }
                          onBlur={() => setFieldTouched("password")}
                        />
                        <HelperText
                          type="error"
                          visible={
                            touched.password && errors.password ? true : false
                          }
                          style={
                            touched.password && errors.password
                              ? { paddingBottom: 10 }
                              : {
                                  height: 0,
                                  maxHeight: 10
                                }
                          }
                        >
                          {errors.password}
                        </HelperText>

                        <TextInput
                          testID="passwordConfirm"
                          label="confirma contraseña"
                          secureTextEntry={true}
                          value={values.passswordConfirm}
                          onChangeText={value =>
                            setFieldValue("passwordConfirm", value)
                          }
                          onBlur={() => setFieldTouched("passwordConfirm")}
                        />
                        <HelperText
                          type="error"
                          visible={
                            touched.passwordConfirm && errors.passwordConfirm
                              ? true
                              : false
                          }
                          style={
                            touched.passwordConfirm && errors.passwordConfirm
                              ? { paddingBottom: 10 }
                              : {
                                  height: 0,
                                  maxHeight: 10
                                }
                          }
                        >
                          {errors.passwordConfirm}
                        </HelperText>

                        <Button
                          color="#3f51b5"
                          testID="sendRegister"
                          onPress={handleSubmit}
                          style={{ padding: 3 }}
                          // icon={<Icon name="checkcircle" size={15} color="white" />}
                          // iconRight

                          mode="contained"
                        >
                          Regístrarse
                        </Button>
                        <Button
                          color="#3f51b5"
                          mode="outlined"
                          style={{ marginTop: 10, padding: 3 }}
                          onPress={() => {
                            Platform.OS === "ios"
                              ? routeNavigation(this.props).navigate("/Login")
                              : routeNavigation(this.props).push("/Login");
                          }}
                          style={{ marginTop: 10, padding: 3 }}
                          // icon={<Icon name="checkcircle" size={15} color="white" />}
                          // iconRight
                        >
                          Volver a Inicio sesión
                        </Button>
                      </View>
                    );
                  }}
                </Formik>
              </KeyboardAvoidingView>
            );
          }}
        </Subscribe>
      </SafeAreaView>
    );
  }
}

export default withApollo(withNavigationRouter(RegisterWithMail));
