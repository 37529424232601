import React, { useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router";
import { View } from "react-native";
import { withTheme } from "styled-components/native";
import { css } from "styled-components";

import Stage from "../../components/Stage";
import { isDesktop as isDesktopp } from "../../utils";
import { ParagraphText } from "../../components/styled";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    ...(!isDesktopp
      ? {
          maxHeight: "calc(100vh - 50px)",
          overflowY: "auto",
        }
      : {}),
  },
}));

function TabBar({ theme, theme: { isDesktop, maxWidth } }) {
  const classes = useStyles();
  const [value, setValue] = useLocalStorage("periodo-history", 0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  // return null;
  return (
    <div
      css={css`
        ${theme.bp.desktop(css`
          max-width: ${maxWidth}px;
          min-width: ${maxWidth}px;
          margin: auto;
          padding-top: 25px;
          height: 100%;
        `)}
      `}
    >
      <div
        css={css`
          ${theme.bp.desktop(css`
            max-width: ${maxWidth - 45}px;
            min-width: ${maxWidth - 45}px;
            margin: 0 auto;
          `)}
        `}
      >
        <Paper square>
          {/* <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={value}
            onChange={handleChange}
          >
            <Tab
              label="Período 1"
              style={{ minWidth: "33.3%", width: "33.3%" }}
            />
            <Tab
              label="Período 2"
              style={{ minWidth: "33.3%", width: "33.3%" }}
            />
            <Tab
              label="Período 3"
              style={{ minWidth: "33.3%", width: "33.3%" }}
            />
          </Tabs> */}
        </Paper>
      </div>
      {value === 0 && <Stage route={{ key: "first" }} />}
      {value === 1 && <Stage route={{ key: "second" }} />}
      {value === 2 && <Stage route={{ key: "third" }} />}
      {/* {value === 2 && (
        <View
          style={{
            zIndex: -1,
            top: 40,
            postion: "relative",
            flex: 1,
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <ParagraphText bold>Próximamente nuevas unidades</ParagraphText>
          <ParagraphText>
            Ciudadanía Digital (Cyber Bullying, Sexting, Grooming y Adicción
            digital)
          </ParagraphText>
          <ParagraphText>Migrantes (Vivir en diversidad) </ParagraphText>
          <ParagraphText>Juegos (Sana convivencia en el recreo)</ParagraphText>
        </View>
      )} */}
    </div>
  );
}

// Hook
function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export default withTheme(withRouter(TabBar));
