import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";
import { useQuery, useLazyQuery } from "react-apollo";
import { Button, TextInput } from "react-native-paper";
import { css } from "styled-components/native";
import gql from "graphql-tag";
import colors from "../../constants/Colors";

const RESET_PASSWORD_QUERY = gql`
  query resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

const LostPassword = ({ handleShowReset }) => {
  const [email, setEmail] = useState("");
  const [resetPassword, { loading, error, data }] = useLazyQuery(
    RESET_PASSWORD_QUERY,
    {
      variables: {
        email
      }
    }
  );

  useEffect(() => {
    if (!loading && !error && data) {
      setTimeout(() => handleShowReset(), 20000);
    }
  }, [loading]);

  if (!loading && !error && data) {
    return (
      <View>
        <Text>
          Te hemos enviado un correo electrónico con las instrucciones para
          restablecer tu contraseña. Si no lo ves en la bandeja de entrada
          revisa tu carpeta de spam.
        </Text>
      </View>
    );
  }

  return (
    <View>
      <Text
        css={css`
          margin-bottom: 5px;
        `}
      >
        Ingresa tu correo electrónico:
      </Text>
      <TextInput
        autoCapitalize="none"
        label="correo electrónico"
        value={email}
        onChangeText={value => setEmail(value)}
      />
      <Button
        color={colors.primary}
        mode="outlined"
        onPress={() => email && resetPassword()}
        loading={loading}
        disabled={loading}
        css={css`
          margin-top: 10px;
          padding: 3px;
        `}
      >
        {loading ? "Enviando..." : " Restablece tu contraseña "}
      </Button>
    </View>
  );
};

export default LostPassword;
