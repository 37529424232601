import color from "../constants/Colors";
import { Dimensions, Platform } from "react-native";

const spacing = {
  small: 10,
  normal: 14,
  big: 20,
  default: 10,
  huge: 30,
  xHuge: 40,
  xxHuge: 60,
  noMargin: 0
};

const flexAlign = {
  center: "center",
  left: "flex-start",
  right: "flex-end",
  default: "flex-start"
};
const textAlign = {
  center: "center",
  left: "left",
  right: "right",
  default: "left"
};

const size = {
  stiny: 10,
  tiny: 12,
  default: 14,
  small: 14,
  medium: 16,
  xmedium: 20,
  large: 24,
  xlarge: 32,
  xxlarge: 46,
  xxxlarge: 52
};

const extra = {
  underline: "underline"
};

const isDesktop = (strings, ...values) => {
  if (
    !["ios", "android"].includes(Platform.OS) &&
    Dimensions.get("window").width >= 1024
  ) {
    return strings.reduce((result, string, i) => {
      return `${result}${string}${values[i] || ""}`;
    }, "");
  }

  return "";
};

const setupMaxWidth = (() => {
  let width = Dimensions.get("window").width;

  if (width <= 1280) {
    return width;
  } else {
    return 1280;
  }
})();

export default {
  maxWidth: setupMaxWidth,
  extra,
  isDesktop,
  color,
  flexAlign,
  size,
  textAlign,
  spacing
};
