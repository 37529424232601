import React from "react";
import { Text, Paragraph } from "react-native-paper";
import { TouchableWithoutFeedback, View } from "react-native";
import { withNavigationRouter, routeNavigation } from "../../withReactRouter";
import { withTheme } from "styled-components/native";
import { ParagraphText } from "../../components/styled";
import { css } from "styled-components";
import { Suggestion, SuggestionText } from "./styles";
import { isMobileWeb } from "../../utils";

function Step(props) {
  let { isPrint, steps, navigation, theme, isSuggestion = false } = props;

  let hasLink = function hasLinkRef(step, isPrint) {
    let hasLink = /<[^>]+/i.test(step);
    if (hasLink) {
      var hasLinkText = step.match(/<[^>]+/i)[0].split("");
      hasLinkText.shift();
      hasLinkText = hasLinkText.join("");

      //first bug with description
      if (step.includes('"icon":"image"')) {
        var attachment = hasLinkText.match(/attachmentObject={".*/i);
        var attachmentStart = attachment.index - 1;
        var attachmentLength = attachment[0].length;
        var attachmentText = attachment[0].slice(17, attachmentLength);

        var attachmentObj = JSON.parse(attachmentText);
        var indexStartOfLink = step.indexOf(hasLinkText);
        var startOfText = step.slice(0, indexStartOfLink - 1);
        var endOfText = step.slice(
          indexStartOfLink + hasLinkText.slice().length + 1,
          step.length
        );
      } else {
        var attachment = hasLinkText.match(/attachmentObject={"[^}]*/i);

        var attachmentStart = attachment.index - 1;
        var attachmentLength = attachment[0].length;

        var attachmentText = step
          .match(/attachmentObject={"[^}]*/i)[0]
          .split("{")[1];

        var attachmentObj = JSON.parse(["{", attachmentText, "}"].join(""));

        var indexStartOfLink = step.indexOf(hasLinkText);
        var startOfText = step.slice(0, indexStartOfLink - 1);
        var endOfText = step.slice(
          indexStartOfLink + hasLinkText.slice().length + 1,
          step.length
        );
      }

      return (
        <>
          <ParagraphText isPrint={isPrint}>{startOfText}</ParagraphText>
          <TouchableWithoutFeedback
            onPress={() => {
              if (attachmentObj.icon === "play-arrow") {
                return routeNavigation(props).push("Player", {
                  videoUrl: attachmentObj.data,
                  placeholder: attachmentObj.placeholder
                });
              }

              if (attachmentObj.icon === "uri") {
                window.open(attachmentObj.data, '_blank');
                return;
              }

              routeNavigation(props).push("Attached", {
                data: attachmentObj.data || "",
                type: attachmentObj.icon
              });
            }}
          >
            <ParagraphText isPrint={isPrint} underline={!isPrint}>
              {hasLinkText.slice(0, attachmentStart)}
            </ParagraphText>
          </TouchableWithoutFeedback>
          <ParagraphText isPrint={isPrint}>
            {hasLinkRef(endOfText)}
          </ParagraphText>
        </>
      );
    }
    return step;
  };

  if (isSuggestion) {
    return (
      <Suggestion>
        <SuggestionText isPrint={isPrint}>Sugerencia:</SuggestionText>
        <ParagraphText isPrint={isPrint} marginBottom italic>
          {steps}
        </ParagraphText>
      </Suggestion>
    );
  }

  return steps.map((step, index) => {
    return (
      <View
        key={index}
        css={css`
          padding: 0 10px;
        `}
      >
        <Text
          css={`
            margin-top: 10px;
            font-weight: bold;
            ${theme.bp.desktop`
              font-size: ${!isPrint ? theme.size.xmedium : theme.size.small}px;
            `}
          `}
        >
          Paso {index + 1}{" "}
        </Text>
        <ParagraphText isPrint={isPrint} marginBottom>
          {hasLink(step, isPrint)}
        </ParagraphText>
      </View>
    );
  });
}

export default withTheme(withNavigationRouter(Step));
