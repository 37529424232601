import * as Yup from "yup";

const msg = {
  email: {
    email: "Formato email no válido",
    required: "Campo email es obligatorio",
    oneOf: "Emails no coinciden"
  },
  password: {
    min: "Contraseña debe tener al menos 6 caracteres",
    required: "Campo contraseña es obligatorio"
  }
};

export default Yup.object().shape({
  email: Yup.string()
    .max(255)
    .email(msg.email.email)
    .required(msg.email.required),
  password: Yup.string()
    .min(6, msg.password.min)
    .max(255)
    .required(msg.password.required)
});
