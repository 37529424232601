import * as React from "react";
import {
  RadioButton,
  Text,
  Button,
  Dialog,
  Portal,
  Provider
} from "react-native-paper";
import { View, StyleSheet } from "react-native";

export default class SelectPositionDialog extends React.Component {
  state = {
    visible: false,
    value: ""
  };

  _hideDialog = () => this.props.setToggleDialog();

  render() {
    return (
      <Portal>
        <Dialog
          visible={this.props.toggleDialog}
          onDismiss={this._hideDialog}
          style={styles.dialog}
        >
          <RadioButton.Group
            onValueChange={value => {
              this.props.setPosition(value);
              this.setState({ value });
              setTimeout(() => this._hideDialog(), 400);
            }}
            value={this.state.value}
            style={{ maxWidth: 200, minWidth: 200 }}
          >
            <View style={styles.wrapper}>
              <Text>Docente</Text>
              <RadioButton value="Docente" />
            </View>
            <View style={styles.wrapper}>
              <Text>Encargado(a) Convivencia</Text>
              <RadioButton value="Encargado Convivencia" />
            </View>
            <View style={styles.wrapper}>
              <Text>Director(a)</Text>
              <RadioButton value="Director" />
            </View>
            <View style={styles.wrapper}>
              <Text>Equipo Psicosocial</Text>
              <RadioButton value="Equipo Psicosocial" />
            </View>
            <View style={styles.wrapper}>
              <Text>Otro</Text>
              <RadioButton value="Otro" />
            </View>
          </RadioButton.Group>
        </Dialog>
      </Portal>
    );
  }
}

const styles = StyleSheet.create({
  dialog: {
    maxWidth: 200,
    minWidth: 200,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: [{ translateX: "-50%" }, { translateY: "-50%" }]
  },
  wrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 50,
    maxHeight: 50,
    marginLeft: 10
  }
});
