import React, { Component } from "react";
import { View, KeyboardAvoidingView, Platform, Dimensions } from "react-native";
import { SafeAreaView } from "react-navigation";
import { Formik } from "formik";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { Button, HelperText, TextInput, Text } from "react-native-paper";

import { isDesktop, isNative } from "../../utils";
import validationSchema from "./validation";
import Notification from "../../components/Notification";
import { withNavigationRouter, routeNavigation } from "../../withReactRouter";
import logoLong from "../../../assets/logo_long.png";
import { LoginImage } from "../../components/styled";
import colors from "../../constants/Colors";

const NEW_PASSWORD_MUTATION = gql`
  mutation newPassword($resetPasswordToken: String!, $password: String!) {
    newPassword(password: $password, resetPasswordToken: $resetPasswordToken) {
      status
      message
    }
  }
`;

class LostPasswordReset extends Component {
  _notificationRef = React.createRef();
  _notificationRefExpired = React.createRef();

  state = {
    success: false
  };

  render() {
    return (
      <SafeAreaView
        style={{
          flex: 1,
          justifyContent: "center",
          maxWidth: 500,
          ...(isDesktop && isNative
            ? {}
            : {
                transform: "translateX(-50%)",
                left: "50%"
              })
        }}
      >
        <KeyboardAvoidingView behavior="padding">
          <Formik
            initialValues={{
              password: "",
              passwordConfirm: ""
            }}
            validationSchema={validationSchema}
            onSubmit={async ({ password }) => {
              try {
                let result = await this.props.client.mutate({
                  mutation: NEW_PASSWORD_MUTATION,
                  variables: {
                    password,
                    resetPasswordToken: this.props.match.params
                      .resetPasswordToken
                  }
                });

                let { status, message } = result.data.newPassword;

                if (status === "ok") {
                  this.setState({ success: true }, () => {
                    setTimeout(() => {
                      routeNavigation(this.props).push("/Login");
                    }, 4000);
                  });

                  return;
                }

                if (status === "error" && message.includes("expirado")) {
                  return this._notificationRefExpired.current.showNotification();
                }

                if (status === "error") {
                  this._notificationRef.current.showNotification();
                }
              } catch (e) {
                console.log("catch", e);
                this._notificationRef.current.showNotification();
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              isSubmitting
            }) => (
              <View
                style={{
                  padding: 10,
                  paddingTop: 0,
                  height: "100%",
                  justifyContent: "center"
                }}
              >
                <Notification ref={this._notificationRefExpired}>
                  La URL para restablecer la contraseña ha expirado
                </Notification>
                <Notification ref={this._notificationRef}>
                  Error al cambiar contraseña
                </Notification>
                <LoginImage source={logoLong} />

                {this.state.success && (
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <Text>
                      Tu contraseña se ha restablecido exitosamente!
                      Redirigiendo a login...
                    </Text>
                  </View>
                )}

                {!this.state.success && (
                  <>
                    <Text style={{ marginBottom: 5 }}>
                      Escribe tu nueva contraseña:
                    </Text>
                    <TextInput
                      label="Nueva contraseña"
                      autoCapitalize="none"
                      value={values.password}
                      secureTextEntry
                      onChangeText={value => setFieldValue("password", value)}
                      onBlur={() => setFieldTouched("password")}
                    />
                    <HelperText
                      type="error"
                      visible={
                        touched.password && errors.password ? true : false
                      }
                      style={
                        touched.password && errors.password
                          ? { paddingBottom: 10 }
                          : {
                              height: 0,
                              maxHeight: 10
                            }
                      }
                    >
                      {errors.password}
                    </HelperText>

                    <TextInput
                      label="Confirmar nueva contraseña"
                      autoCapitalize="none"
                      value={values.passwordConfirm}
                      secureTextEntry
                      onChangeText={value =>
                        setFieldValue("passwordConfirm", value)
                      }
                      onBlur={() => setFieldTouched("passwordConfirm")}
                    />
                    <HelperText
                      type="error"
                      visible={
                        touched.passwordConfirm && errors.passwordConfirm
                          ? true
                          : false
                      }
                      style={
                        touched.passwordConfirm && errors.passwordConfirm
                          ? { paddingBottom: 10 }
                          : {
                              height: 0,
                              maxHeight: 10
                            }
                      }
                    >
                      {errors.passwordConfirm}
                    </HelperText>

                    <Button
                      color={colors.primary}
                      onPress={handleSubmit}
                      mode="contained"
                      style={{ padding: 3 }}
                    >
                      Restablecer contraseña
                    </Button>
                  </>
                )}
              </View>
            )}
          </Formik>
        </KeyboardAvoidingView>
      </SafeAreaView>
    );
  }
}

export default withApollo(withNavigationRouter(LostPasswordReset));
