import { Container } from "unstated";

/**
 * Componente Container que guarda el estado del login. Se puede acceder a este estado ocupandolo como wrapper en un componente que quiere obtener o cambiar el estado. ref: http://unstated.io
 */
export default class LoginContainer extends Container {
  state = {
    granted: false,
    user: {}
  };

  setGranted = ({ push, user }) => {
    localStorage.setItem("granted", true);
    localStorage.setItem("user", JSON.stringify(user));

    this.setState({ user, granted: true }, () => {
      return push("/Home", user);
    });
  };

  setGrantedRegister = ({ push, user }) => {
    localStorage.setItem("granted", true);
    localStorage.setItem("user", JSON.stringify(user));

    this.setState({ user, granted: true }, () => {
      return push("/registro-exitoso", user);
    });
  };

  setGrantedProjects = ({ push, user }) => {
    localStorage.setItem("granted_projects", true);
    localStorage.setItem("user_projects", JSON.stringify(user));

    this.setState({ user, granted: true }, () => {
      return push("/Proyectos", user);
    });
  };

  logout = push => {
    this.setState({ granted: false, user: {} }, () => {
      const grantedProject =
        JSON.parse(localStorage.getItem("granted_projects")) || false;

      localStorage.setItem("granted", false);
      localStorage.setItem("user", "{}");

      localStorage.setItem("granted_projects", false);
      localStorage.setItem("user_projects", "{}");
      // return grantedProject ? push("/proyectos/login") : push("/");
      return grantedProject ? push("/") : push("/");
    });
  };
}
