import React from "react";
import { FlatList, SafeAreaView, View, Text, AsyncStorage } from "react-native";
import { withLastLocation } from "react-router-last-location";
import withUnstated from "@airship/with-unstated";
import { getNavigationData, routeNavigation } from "../../withReactRouter";
import StyleSheet from "react-native-extended-stylesheet";
import { Redirect } from "react-router-dom";
import { Wrapper, WrapperInner } from "./styles";

import {
  planning as planningData,
  attachments as attachedData,
  sessionInfo,
} from "./utils";
import RenderItem from "../../components/RenderItem";
import HeaderFix from "../../components/HeaderFix.web";
import PlanningContainer from "./PlanningContainer";
import {
  isDesktop,
  isNative,
  capitalizeFirstLetter,
  analytics,
} from "../../utils";
// import { CacheManager } from "react-native-expo-image-cache";

const CURRENT = {
  PLANNING: "planning",
  ATTACHED: "attached",
};

class ErrorBoundary extends React.Component {
  state = {
    error: false,
  };

  componentDidCatch() {
    // this.setState({ error: true });
  }

  render() {
    if (this.state.error) {
      return <Redirect to="/Home" />;
    }

    return this.props.children;
  }
}

class Planning extends React.Component {
  state = {
    current: CURRENT.PLANNING,
    data: [],
    toggle: false,
    isPrint: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isPrint) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }

  handleToggle = () => {
    this.setState({ isPrint: !this.state.isPrint }, () => {
      setTimeout(() => {
        this.setState({ isPrint: !this.state.isPrint });
      }, 1000);
    });
  };

  componentDidMount() {
    analytics.pageHit("Planning");

    let instance = window.amplitude.getInstance();

    instance.logEvent("user_visits_session", {
      titulo: this.state.data.titulo,
      stage: this.state.data.stage,
      period: this.state.data.period,
      level: this.state.data.level,
    });

    let {
      lastLocation = { pathname: "" },
      PlanningContainer: {
        state: { toggle, current },
      },
    } = this.props;

    if (lastLocation === null) {
    } else if (
      lastLocation.pathname === "/" ||
      lastLocation.pathname === "/Home"
    ) {
      return this.setState({ current: CURRENT.PLANNING, toggle: false });
    }

    this.setState({ current, toggle });
  }

  async componentWillMount() {
    try {
      let nav = getNavigationData(this.props, "data");
      this.setState({ data: getNavigationData(this.props, "data") }, () => {
        AsyncStorage.setItem("lastSession", this.state.data);
      });
    } catch (e) {
      this.setState({ data: AsyncStorage.getItem("lastSession") });
    }
  }

  setToggle = () => {
    let { PlanningContainer } = this.props;

    this.setState((prevState) => {
      PlanningContainer.setState({ toggle: !prevState.toggle });
      return { toggle: !prevState.toggle };
    });

    this.setState((prevState) => {
      PlanningContainer.setState({
        current: prevState.toggle ? CURRENT.ATTACHED : CURRENT.PLANNING,
      });
      return {
        current: prevState.toggle ? CURRENT.ATTACHED : CURRENT.PLANNING,
      };
    });
  };

  render() {
    let { toggle, data, isPrint } = this.state;
    let { PlanningContainer } = this.props;
    let isApoderados = data.video === undefined;

    return (
      <>
        {isNative || isPrint ? null : <HeaderFix />}
        <Wrapper isPrint={isPrint}>
          <WrapperInner
            isPrint={isPrint}
            as={FlatList}
            data={[
              ...(!isApoderados
                ? sessionInfo(data)
                : [
                    {
                      type: "H1H2",
                      hasCrisis: data.hasCrisis,
                      h1: data.isCrisis
                        ? data.titulo
                        : `${data.hasCrisis ? `Tips` : `Sesion`} Apoderados`,
                      h2: data.isCrisis
                        ? capitalizeFirstLetter(data.objetivo.toLowerCase())
                        : data.hasCrisis
                        ? "Generar un espacio de conversación familiar sobre la actual crisis desde la educación emocional"
                        : "anexos para realizar presentación y para imprimir",
                    },
                  ]),
              {
                type: data.isIntro ? "Intro" : "Menu",
                current: this.state.current,
              },
              ...(toggle
                ? attachedData(data)
                : planningData({
                    etapas: data,
                    isApoderados: isApoderados && !data.isCrisis,
                  })),
            ]}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => (
              <RenderItem
                data={item}
                setToggle={this.setToggle}
                isPrint={isPrint}
                handleToggle={this.handleToggle}
              />
            )}
          />
        </Wrapper>
      </>
    );
  }
}

export default isNative
  ? Planning
  : withUnstated(withLastLocation(Planning), {
      PlanningContainer,
    });
