import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Text } from "react-native-paper";
import { withTheme } from "styled-components";
import { MenuHR } from "../styled";
import { css } from "styled-components";

class MenuItem extends React.Component {
  render() {
    let { type, theme, current, setToggle } = this.props;
    return (
      <TouchableOpacity
        onPress={() => {
          if (current !== type) {
            setToggle();
          }
        }}
      >
        <View
          style={{
            paddingTop: 20,
            paddingBottom: 15,
            justifyContent: "center",
            position: "relative",
            marginRight: 15,
            marginLeft: 15
          }}
        >
          <MenuHR
            show={
              current === "planning" && type === "planning"
                ? true
                : current === "attached" && type === "attached"
                ? true
                : false
            }
          />
          <Text
            css={`
              font-weight: bold;
              ${theme.bp.desktop`
              font-size: ${theme.size.medium + 2}px ;
             color: ${
               type === "planning" && current === "planning"
                 ? theme.color.softBlack
                 : type !== "planning" && current !== "planning"
                 ? theme.color.softBlack
                 : theme.color.midGrey
             } !important
              `};
            `}
          >
            {type === "planning" ? "PLANIFICACIÓN" : "ANEXOS"}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}
export default withTheme(MenuItem);
