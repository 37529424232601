import React from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  HashRouter,
} from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";

import {
  Home,
  Planning,
  PlanningPrint,
  Player,
  Attached,
  Login,
  Register,
  RegisterWithMail,
  AddEmails,
} from "../../screens";
import ProtectedRoute from "./ProtectedRoute";
import { LostPasswordReset } from "../../screens/LostPassword";
import { isNative } from "../../utils";
import Projects from "../../screens/Projects";
import LoginProjects from "../../screens/Login/LoginProjects";

export default function NavigationWeb() {
  if (
    localStorage.getItem("user") === null ||
    localStorage.getItem("user") === "{}"
  ) {
    localStorage.setItem("user", "{}");
  } else {
    let instance = window.amplitude.getInstance();
    let user = JSON.parse(localStorage.getItem("user"));

    let ampUser = new instance.Identify();

    ampUser.set("fullName", user.fullName).set("email", user.email);

    if (user.school) {
      ampUser
        .set("school_name", user.school.name)
        .set("school_rbd", user.school.rbd);
    }

    instance.logEvent("user_login");

    // debugger;
    // window.amplitude.getInstance().identify(ampUser);
    instance.identify(ampUser, (responseCode, responseBody) => {
      console.log("responseCode=" + responseCode);
      console.log("responsebody=" + responseBody);
    });
  }

  //fix ghost click
  //https://makandracards.com/makandra/51956-event-order-when-clicking-on-touch-devices
  //https://github.com/necolas/react-native-web/issues/1078
  //https://github.com/facebook/react/issues/9809
  //https://gist.github.com/jtangelder/361052976f044200ea17
  if (!isNative) {
    window.addEventListener(
      "touchstart",
      function(e) {
        e.stopPropagation();
        console.log("touchstart");
      },
      true
    );
    window.addEventListener(
      "touchend",
      function(e) {
        e.stopPropagation();
        console.log("touchend");
      },
      true
    );
  }

  return (
    <BrowserRouter>
      <LastLocationProvider>
        <Route
          component={() => {
            window.scrollTo(0, 0);
            return null;
          }}
        />
        <Switch>
          <Route exact path={"/proyectos/login"} component={LoginProjects} />
          <Route exact path={"/proyectos"} component={Projects} />
          <ProtectedRoute exact path={"/registro-exitoso"} component={Home} />
          <ProtectedRoute exact path={"/Home"} component={Home} />
          <Route exact path={"/Login"} component={Login} />
          <Route exact path={"/Register"} component={Register} />
          <Route exact path={"/Register/:email"} component={RegisterWithMail} />
          <Route exact path={"/Session"} component={Planning} />
          <Route exact path={"/Player"} component={Player} />
          <Route exact path={"/Attached"} component={Attached} />
          <Route
            exact
            path={"/Reset/:resetPasswordToken"}
            component={LostPasswordReset}
          />
          <Route exact path={"/admin/"} component={AddEmails} />
          {/* idea es tener iframe con sheet y btn agregar */}
          {/* si usuario es pablo o yo se carga o sino hay redirect a home */}
          {/* iframe ocupa todo el espacio hay un boton con fixed abajo a la derecha para agregar */}
          <Route
            exact
            path={"/"}
            component={(props) => <Home {...props} isVisit={true} />}
          />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </LastLocationProvider>
    </BrowserRouter>
  );
}
