import React from "react";
import { Headline as HeadlinePaper } from "react-native-paper";
import styled from "styled-components/native";
import { isMobileWeb } from "../../utils";

export const Spacer = styled.View`
  height: 40px;
`;

export const Headline = styled(HeadlinePaper)`
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 0)};
  font-size: 20;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 300)};
`;

export const Wrapper = styled.View`
  background-color: transparent;
  padding: ${isMobileWeb ? "0 8px" : "0 25px"};
  position: relative;
`;
