import React, { Component } from "react";
import { View, KeyboardAvoidingView, Platform, Dimensions } from "react-native";
import { SafeAreaView } from "react-navigation";
import { Formik } from "formik";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";
import { Button, HelperText, TextInput } from "react-native-paper";
import { css } from "styled-components";
import { Subscribe } from "unstated";
import { Redirect } from "react-router-dom";

import { isDesktop, isNative, analytics } from "../../utils";
import validationSchema from "./validation";
import Notification from "../../components/Notification";
import { withNavigationRouter, routeNavigation } from "../../withReactRouter";
import logoLong from "../../../assets/logo_long.png";
import { LoginImage } from "../../components/styled";
import colors from "../../constants/Colors";
import LoginContainer from "./LoginContainer";
import { LostPassword } from "../LostPassword/index.js";
import { Analytics, PageHit } from "expo-analytics";

const LOGIN_QUERY = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      fullName
      email
      accountType
      quote
      school {
        rbd
        name
      }
    }
  }
`;

class Login extends Component {
  _notificationRef = React.createRef();

  async componentWillMount() {
    let granted = JSON.parse(localStorage.getItem("granted"));
    let user = JSON.parse(localStorage.getItem("user"));

    if (granted) {
      isNative
        ? routeNavigation(this.props).navigate("/Home", user)
        : routeNavigation(this.props).push("/Home", user);
    }
  }

  componentDidMount() {
    analytics.pageHit("Login");
  }

  state = {
    showReset: false,
  };

  handleShowReset = () => this.setState({ showReset: !this.state.showReset });

  render() {
    let { showReset } = this.state;
    return (
      <SafeAreaView
        style={{
          flex: 1,
          justifyContent: "center",
          maxWidth: 500,
          ...(isDesktop && isNative
            ? {}
            : {
                transform: "translateX(-50%)",
                left: "50%",
              }),
        }}
      >
        {showReset && (
          <View
            style={{
              padding: 10,
              paddingTop: 0,
              height: "100%",
              justifyContent: "center",
            }}
          >
            <LoginImage source={logoLong} />
            <LostPassword handleShowReset={this.handleShowReset} />
          </View>
        )}

        {!showReset && (
          <Subscribe to={[LoginContainer]}>
            {(login) => {
              return (
                <KeyboardAvoidingView behavior="padding">
                  {login.state.granted ? <Redirect to="/Home" /> : null}
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      // email: "cristian@educaswitch.com",
                      // password: "unouno"
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                      try {
                        let {
                          data: { login: user },
                        } = await this.props.client.query({
                          query: LOGIN_QUERY,
                          variables: {
                            ...values,
                          },
                        });

                        login.setGranted({
                          push: routeNavigation(this.props).push,
                          user,
                        });

                        // Platform.OS === "ios"
                        //   ? routeNavigation(this.props).navigate("Home", user)
                        //   : routeNavigation(this.props).push("Home", user);
                      } catch (e) {
                        console.log("catch", e);
                        this._notificationRef.current.showNotification();
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleSubmit,
                      setFieldTouched,
                      setFieldValue,
                      isSubmitting,
                    }) => (
                      <View
                        style={{
                          padding: 10,
                          paddingTop: 0,
                          height: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <Notification ref={this._notificationRef}>
                          Login Incorrecto
                        </Notification>

                        <LoginImage source={logoLong} />
                        <TextInput
                          testID="emailLogin"
                          keyboardType="email-address"
                          autoCapitalize="none"
                          label="Correo electrónico"
                          value={values.email}
                          onChangeText={(value) =>
                            setFieldValue("email", value.toLowerCase())
                          }
                          onBlur={() => setFieldTouched("email")}
                        />
                        <HelperText
                          type="error"
                          visible={touched.email && errors.email ? true : false}
                          style={
                            touched.email && errors.email
                              ? { paddingBottom: 10 }
                              : {
                                  height: 0,
                                  maxHeight: 10,
                                }
                          }
                        >
                          {errors.email}
                        </HelperText>

                        <TextInput
                          label="Contraseña"
                          testID="passwordLogin"
                          autoCapitalize="none"
                          value={values.password}
                          secureTextEntry
                          onChangeText={(value) =>
                            setFieldValue("password", value)
                          }
                          onBlur={() => setFieldTouched("password")}
                        />
                        <HelperText
                          type="error"
                          visible={
                            touched.password && errors.password ? true : false
                          }
                          style={
                            touched.password && errors.password
                              ? { paddingBottom: 10 }
                              : {
                                  height: 0,
                                  maxHeight: 10,
                                }
                          }
                        >
                          {errors.password}
                        </HelperText>

                        <Button
                          color={colors.primary}
                          testID="sendLogin"
                          onPress={handleSubmit}
                          mode="contained"
                          style={{ padding: 3 }}
                        >
                          Iniciar sesión
                        </Button>

                        <Button
                          color={colors.primary}
                          mode="outlined"
                          testID="goToRegister"
                          onPress={() =>
                            isNative
                              ? routeNavigation(this.props).navigate("Register")
                              : routeNavigation(this.props).push("Register")
                          }
                          css={css`
                            margin-top: 10px;
                            padding: 3px;
                          `}
                        >
                          Regístrate
                        </Button>

                        <Button
                          color={colors.midGrey}
                          onPress={this.handleShowReset}
                          style={{ paddingTop: 30 }}
                        >
                          ¿Olvidaste tu contraseña?
                        </Button>
                      </View>
                    )}
                  </Formik>
                </KeyboardAvoidingView>
              );
            }}
          </Subscribe>
        )}
      </SafeAreaView>
    );
  }
}

export default withApollo(withNavigationRouter(Login));
