import React, { Component } from "react";
import { Subscribe } from "unstated";
import { Route, Redirect } from "react-router-dom";
import LoginContainer from "../../screens/Login/LoginContainer";

export default class ProtectedRoute extends Component {
  render() {
    return (
      <Subscribe to={[LoginContainer]}>
        {login => {
          if (
            JSON.parse(localStorage.getItem("granted")) ||
            login.state.granted
          ) {
            return <Route {...this.props} />;
          }

          return <Redirect to="/Login" />;
        }}
      </Subscribe>
    );
  }
}
