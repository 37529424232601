import React, { Component } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  AsyncStorage
} from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";
import { connectActionSheet } from "@expo/react-native-action-sheet";
import withUnstated from "@airship/with-unstated";

import { DataContext } from "../../App";
import { Subscribe } from "unstated";
import LevelContainer from "./LevelContainer";
import HeaderModal from "./HeaderModal.web";
import { withNavigationRouter, routeNavigation } from "../withReactRouter";
import { isDesktop } from "../utils";

@connectActionSheet
class Header extends React.PureComponent {
  state = {
    visible: false,
    checked: "first",
    loading: true
  };

  headerModal = React.createRef();

  showDialog = () => this.setState({ visible: true });

  hideDialog = () => this.setState({ visible: false });

  async componentDidMount() {
    // let res = await AsyncStorage.getItem("level-history");
    // this.props.levelContainer.setCurrentLevel(Number(res));
  }

  _openSheetOrModal = setCurrentLevel => {
    console.log("ths.headerModal", this.headerModal);
    // if (this.isDesktop) {
    //   return this.headerModal.current.handleClickOpen();
    // }

    this._onOpenActionSheet(setCurrentLevel);
  };

  _onOpenActionSheet = async setCurrentLevel => {
    const options = [
      "Pre-kínder a 2º básico",
      "3º básico a 6º básico",
      "7º básico a IV medio"
      // "Cancelar"
    ];
    // const destructiveButtonIndex = 0;
    const cancelButtonIndex = 3;

    this.props.showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex
        // destructiveButtonIndex
      },
      async buttonIndex => {
        if (buttonIndex === 3) return;
        setCurrentLevel(buttonIndex + 1);
        await AsyncStorage.setItem(
          "level-history",
          JSON.stringify(buttonIndex + 1)
        );
      }
    );
  };

  render() {
    const { checked, loading } = this.state;
    let { pathname } = this.props.location;
    // console.log("pathname => ", pathname);

    // return null;
    return (
      <Subscribe to={[LevelContainer]}>
        {({ setCurrentLevel, state }) => (
          <DataContext.Consumer>
            {data => (
              <>
                <HeaderModal
                  ref={this.headerModal}
                  setCurrentLevel={setCurrentLevel}
                />
                <TouchableOpacity
                  onPress={() => this._openSheetOrModal(setCurrentLevel)}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      minHeight: 50,
                      justifyContent: "center"
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 17,
                        fontWeight: "600",
                        color: "rgba(0, 0, 0, 0.54)",
                        backgroundColor: "white"
                      }}
                    >
                      {state.name}
                    </Text>
                    <Ionicons
                      name="md-arrow-dropdown"
                      size={20}
                      style={{ marginLeft: 5 }}
                      color="rgba(0, 0, 0, 0.54)"
                    />
                  </View>
                </TouchableOpacity>
                <View
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "#eee"
                  }}
                />
              </>
            )}
          </DataContext.Consumer>
        )}
      </Subscribe>
    );
  }
}

export default withNavigationRouter(
  withUnstated(Header, { levelContainer: LevelContainer })
);
