import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";
import { css } from "styled-components";
import gql from "graphql-tag";
import { Dimensions, View, TouchableOpacity, StyleSheet } from "react-native";

import { useMutation } from "react-apollo";
import { TextInputMask } from "react-native-masked-text";
import * as Icon from "@expo/vector-icons";

import LoginContainer from "../screens/Login/LoginContainer";
import { isMobileWeb } from "../utils";
import {
  TextInput,
  HelperText,
  Title,
  Paragraph,
  Caption,
  Divider
} from "react-native-paper";

const TourModal = props => {
  const { handleClose, selectedValue, open, isVisit, ...other } = props;

  const isDemo = (() => {
    return JSON.parse(localStorage.getItem("user")).accountType === "demo";
  })();

  return (
    <Subscribe to={[LoginContainer]}>
      {login => {
        let isLoggedIn = Boolean(
          Object.keys(JSON.parse(localStorage.getItem("user"))).length
        );

        return (
          <Dialog
            onClose={() => {
              handleClose();
            }}
            fullWidth={true}
            maxWidth={"md"}
            aria-labelledby="simple-dialog-title"
            open={open}
            style={{ maxWidth: "100%" }}
          >
            <TouchableOpacity onPress={handleClose}>
              <View
                style={{
                  position: "fixed",
                  margin: 16,
                  right: 10,
                  top: 10,
                  zIndex: 99999
                }}
              >
                <Icon.MaterialIcons name="close" color="white" size={33} />
              </View>
            </TouchableOpacity>

            <div
              css={css`
                margin: 30px 30px;
                color: black;
                font-family: arial;
                font-size: 18px;
                padding: ${isMobileWeb ? "0px" : "inherit"};
                display: flex;
                flex-direction: column;
                min-width: ${Dimensions.get("window").width * 0.6};
                height: ${Dimensions.get("window").width * 0.3};
              `}
            >
              <iframe
                src={
                  isDemo || isVisit
                    ? "https://www.youtube.com/embed/FQ19AWcEszU"
                    : "https://www.youtube.com/embed/kNQk3QcOVyw"
                }
                height={Dimensions.get("window").width * 0.3}
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
          </Dialog>
        );
      }}
    </Subscribe>
  );
};

export default TourModal;
