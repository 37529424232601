import * as Yup from "yup";

const msg = {
  email: {
    email: "Formato email no válido",
    required: "Campo obigatorio",
    oneOf: "Emails no coinciden"
  },
  school: {
    required: "Campo obigatorio",
    fromList: "Agrega tu colegio"
  },
  position: {
    required: "Campo obigatorio"
  },
  positionOptional: {
    required: "Campo obigatorio"
  },
  emailConfirm: {
    oneOf: "Campos email no coinciden",
    email: "Formato email no válido",
    required: "Campo obigatorio"
  },
  fullName: {
    min: "Nombre Completo debe tener al menos 10 caracteres",
    required: "Campo obigatorio"
  },
  password: {
    min: "Contraseña debe tener al menos 6 caracteres",
    required: "Campo obigatorio"
  },
  passwordConfirm: {
    oneOf: "Contraseñas no coinciden",
    min: "Contraseña debe tener al menos 6 caracteres",
    required: "Campo obligatorio"
  }
};

export const registerManualSchool = Yup.object().shape({
  email: Yup.string()
    .max(255)
    .email(msg.email.email)
    .required(msg.email.required),
  emailConfirm: Yup.string()
    .max(255)
    .oneOf([Yup.ref("email")], msg.emailConfirm.oneOf)
    .email(msg.emailConfirm.email)
    .required(msg.email.required),
  fullName: Yup.string()
    .min(10, msg.fullName.min)
    .max(255)
    .required(msg.fullName.required),
  position: Yup.string().required(msg.position.required),
  schoolName: Yup.string().required("campo obligatorio"),
  schoolRegion: Yup.string().required("campo obligatorio"),
  schoolComuna: Yup.string().required("campo obligatorio"),
  password: Yup.string()
    .min(6, msg.password.min)
    .max(255)
    .required(msg.password.required),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], msg.passwordConfirm.oneOf)
    .min(6, msg.passwordConfirm.min)
    .max(255)
    .required(msg.passwordConfirm.required)
});

export const registerWithoutSchool = Yup.object().shape({
  email: Yup.string()
    .max(255)
    .email(msg.email.email)
    .required(msg.email.required),
  emailConfirm: Yup.string()
    .max(255)
    .oneOf([Yup.ref("email")], msg.emailConfirm.oneOf)
    .email(msg.emailConfirm.email)
    .required(msg.email.required),
  fullName: Yup.string()
    .min(10, msg.fullName.min)
    .max(255)
    .required(msg.fullName.required),
  position: Yup.string().required(msg.position.required),
  positionOptional: Yup.string().required(msg.positionOptional.required),
  password: Yup.string()
    .min(6, msg.password.min)
    .max(255)
    .required(msg.password.required),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], msg.passwordConfirm.oneOf)
    .min(6, msg.passwordConfirm.min)
    .max(255)
    .required(msg.passwordConfirm.required)
});

export default Yup.object().shape({
  email: Yup.string()
    .max(255)
    .email(msg.email.email)
    .required(msg.email.required),
  school: Yup.string()
    .matches(/^[^-]*-[^-]*-[^:]*:\s[0-9]+$/, {
      excludeEmptyString: true,
      message: msg.school.fromList
    })
    .required(msg.school.required),
  emailConfirm: Yup.string()
    .max(255)
    .oneOf([Yup.ref("email")], msg.emailConfirm.oneOf)
    .email(msg.emailConfirm.email)
    .required(msg.email.required),
  fullName: Yup.string()
    .min(10, msg.fullName.min)
    .max(255)
    .required(msg.fullName.required),
  position: Yup.string().required(msg.position.required),
  password: Yup.string()
    .min(6, msg.password.min)
    .max(255)
    .required(msg.password.required),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], msg.passwordConfirm.oneOf)
    .min(6, msg.passwordConfirm.min)
    .max(255)
    .required(msg.passwordConfirm.required)
});

export const registerWithEmail = Yup.object().shape({
  school: Yup.string()
    .matches(/^[^-]*-[^-]*-[^:]*:\s[0-9]+$/, {
      excludeEmptyString: true,
      message: msg.school.fromList
    })
    .required(msg.school.required),
  fullName: Yup.string()
    .min(10, msg.fullName.min)
    .max(255)
    .required(msg.fullName.required),
  password: Yup.string()
    .min(6, msg.password.min)
    .max(255)
    .required(msg.password.required),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], msg.passwordConfirm.oneOf)
    .min(6, msg.passwordConfirm.min)
    .max(255)
    .required(msg.passwordConfirm.required)
});
