// scroll not working original
import React, { Component } from "react";
import { View, ScrollView } from "react-native";
import { ScreenOrientation } from "expo";
import styled, { css } from "styled-components/native";
import { Subscribe } from "unstated";
import { Headline, Wrapper, Spacer } from "./styles";

import Card from "../Card";
//TODO en vez de context query mejorado para cada componente?
import { DataContext } from "../../../App";
import Fab from "./Fab.web";
import LevelContainer from "../LevelContainer";
import { isMobileWeb } from "../../utils";

class Stage extends Component {
  async componentWillMount() {
    if (isMobileWeb) return;

    try {
      await ScreenOrientation.allowAsync(
        ScreenOrientation.Orientation.PORTRAIT
      );
    } catch (e) {}
  }

  state = {
    "0": "right",
    "1": "right",
    "2": "right",
    "3": "right",
    layout: {},
    hideFav: false,
    isIntro: false,
    hadCrisis: false,
  };

  //mobile-switch
  isSoon = (index, levelState, indexCard = 10) => {
    if (this.props.route.key === "first" && index !== 0) {
      return false;
    }
    if (this.props.route.key === "second") {
      return false;
    }
    if (this.props.route.key === "third" && index === 1) {
      return false;
    } else if (this.props.route.key === "third" && index === 2) {
      return false;
    } else {
      return true;
    }
  };

  isBlocked = (index, levelState, indexCard = 10, isCrisis = false) => {
    if (isCrisis) return false;

    if (levelState === 1) {
      if (this.props.route.key === "first") {
        return false;
      } else if (this.props.route.key === "first" && index === 3) {
        if (indexCard === 0) {
          return false;
        } else {
          return true;
        }
      } else if (this.props.route.key === "second" && index === 1) {
        if (
          indexCard === 0 ||
          indexCard === 1 ||
          indexCard === 2 ||
          indexCard === 3 ||
          indexCard === 4
        ) {
          return false;
        } else {
          return true;
        }
      } else if (this.props.route.key === "second" && index === 2) {
        return false;
      } else if (this.props.route.key === "second" && index === 3) {
        return false;
      } else if (this.props.route.key === "third" && index === 1) {
        return false;
      } else if (this.props.route.key === "third" && index === 2) {
        return false;
      }
    }

    if (levelState === 2) {
      if (this.props.route.key === "first") {
        return false;
      } else if (this.props.route.key === "second" && index === 1) {
        if (
          indexCard === 0 ||
          indexCard === 1 ||
          indexCard === 2 ||
          indexCard === 3 ||
          indexCard === 5
        ) {
          return false;
        } else {
          return true;
        }
      } else if (this.props.route.key === "second" && index === 2) {
        false;
      } else if (this.props.route.key === "second" && index === 3) {
        return false;
      }
    }

    if (levelState === 3) {
      if (this.props.route.key === "first") {
        return false;
      } else if (this.props.route.key === "sxperia-t3econd" && index === 1) {
        if (
          indexCard === 0 ||
          indexCard === 1 ||
          indexCard === 2 ||
          indexCard === 3 ||
          indexCard === 4
        ) {
          return false;
        } else {
          return true;
        }
      } else if (this.props.route.key === "second" && index === 2) {
        return false;
      } else if (this.props.route.key === "second" && index === 3) {
        return false;
      } else if (this.props.route.key === "third") {
        return true;
      }
    }
    return false;
  };

  MAP = {
    first: 1,
    second: 2,
    third: 3,
  };

  filterData = (data) => {
    try {
      let periodo = data.items[0].fields.periodos.find((periodo, index) => {
        return index + 1 === this.MAP[this.props.route.key];
      }).fields.indicadores;

      return periodo;
    } catch (e) {
      console.log("[Error] @ Stage ->", e);
      return [];
    }
  };

  handleScrollButton = ({ index, toggle }) => {
    this["scrollView" + index].scrollTo({ x: toggle ? 40000 : 0, y: 0 });
  };

  cleanTitle = (title) => {
    return title
      .replace("N3", "")
      .replace("N2", "")
      .trim();
  };

  render() {
    let hadCrisis = false;
    return (
      <Subscribe to={[LevelContainer]}>
        {({ state: levelState }) => (
          <DataContext.Consumer>
            {(data) => (
              <ScrollView>
                {this.filterData(data) &&
                  this.filterData(data).map(
                    ({ fields: { tipo, titulo, sesiones } = {} }, index) => {
                      let isCrisis = false;
                      let hasCrisis;
                      if (tipo === "Crisis") {
                        isCrisis = true;
                        hasCrisis = true;
                        hadCrisis = true;
                      } else {
                        isCrisis = false;
                      }

                      {
                        /* debugger; */
                      }
                      const indexIndicador = index;
                      if (sesiones === undefined) return;
                      {
                        /* console.log("sesiones", sesiones); */
                      }
                      //iterar en sesiones y ver si apoderados esta empty. si esta empty contar una sesion menos abajo
                      let substractApoderados;
                      try {
                        substractApoderados = sesiones.some(
                          (sesion, index) => !Boolean(sesion.fields.etapas)
                        )
                          ? 1
                          : 0;
                      } catch {
                        substractApoderados = 0;
                      }

                      return (
                        <View style={{ marginTop: 20 }} key={index}>
                          <Headline marginLeft={isMobileWeb ? 10 : 25}>
                            {`${
                              tipo === "Crisis" ? "Abordando la crisis" : tipo
                            } / `}
                            <Headline fontWeight={500}>
                              {this.cleanTitle(titulo)}
                            </Headline>
                          </Headline>
                          <Wrapper>
                            <View
                              css={css`
                                position: relative;
                              `}
                            >
                              {sesiones.length - substractApoderados >= 5 ? (
                                <Fab
                                  handleScrollButton={this.handleScrollButton}
                                  index={indexIndicador}
                                />
                              ) : null}

                              <ScrollView
                                showsHorizontalScrollIndicator={false}
                                horizontal
                                ref={(ref) =>
                                  (this["scrollView" + index] = ref)
                                }
                              >
                                {sesiones.map((sesion, index) => {
                                  let isIntro = false;
                                  let hasIntro = false;

                                  if (
                                    sesiones[0].sys.contentType.sys.id ===
                                    "sesionIntro"
                                  ) {
                                    hasIntro = true;
                                  }

                                  if (!Boolean(sesion.fields)) return;

                                  if (
                                    sesion.sys.contentType.sys.id ===
                                    "sesionIntro"
                                  ) {
                                    isIntro = true;
                                  } else {
                                    if (
                                      !Boolean(sesion.fields.etapas) &&
                                      !Boolean(sesion.fields.caratulaVideo)
                                    )
                                      return;
                                  }

                                  return (
                                    <Card
                                      hasCrisis={hasCrisis}
                                      isCrisis={isCrisis && !index}
                                      hasIntro={hasIntro}
                                      isIntro={isIntro}
                                      key={`key-${index}-${indexIndicador}`}
                                      navigation={this.props.navigation}
                                      data={{ ...sesion.fields, index }}
                                      isSoon={this.isSoon(
                                        indexIndicador,
                                        levelState.number,
                                        index
                                      )}
                                      isBlocked={() =>
                                        this.isBlocked(
                                          indexIndicador,
                                          levelState.number,
                                          index,
                                          isCrisis
                                        )
                                      }
                                    />
                                  );
                                })}

                                {/* <Card
                                  isCrisis={isCrisis && !index}
                                  isSurvey
                                  period={this.MAP[this.props.route.key]}
                                  stage={indexIndicador + 1}
                                  level={levelState.number}
                                  hadCrisis={hadCrisis}
                                  isSoon={this.isSoon(
                                    indexIndicador,
                                    levelState.number,
                                    index
                                  )}
                                  isBlocked={() =>
                                    this.isBlocked(
                                      indexIndicador,
                                      levelState.number,
                                      index,
                                      isCrisis
                                    )
                                  }
                                /> */}
                              </ScrollView>
                            </View>
                          </Wrapper>
                        </View>
                      );
                    }
                  )}
                <Spacer />
              </ScrollView>
            )}
          </DataContext.Consumer>
        )}
      </Subscribe>
    );
  }
}

export default Stage;
