import React from "react";
import styled, { css } from "styled-components/native";
import styledMap, { mapToTheme as theme } from "styled-map";
import { FlatList } from "react-native";

export const Suggestion = styled.View`
  ${({ theme }) =>
    theme.isDesktop(css`
      padding: 0 20px;
    `)}
`;

export const SuggestionText = styled.Text`
  margin-top: 10px;
  font-weight: bold;
  font-style: italic;
  ${({ theme, theme: { bp }, isPrint }) =>
    bp.desktop(css`
      font-size: ${!isPrint ? theme.size.xmedium : theme.size.small}px;
    `)}
`;
