import React from "react";
import { Dimensions, View } from "react-native";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { Text } from "react-native-paper";
import Feather from "@expo/vector-icons/Feather";

export default function DownloadsScreen() {
  let width = Dimensions.get("window").width / 3;
  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <View
        style={{
          backgroundColor: "lightgray",
          width: width,
          height: width,
          borderRadius: 1000,
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 5
        }}
      >
        <Feather name="download" size={80} color="black" />
      </View>
      <Text style={{ textAlign: "center", padding: 15 }}>
        Aqui van a estar tus descargas de videos para ver offline. Dirigete a un
        video y selecciona la opcion con este icono para que aparesca aca y lo
        puedas consumir sin conexión a internet.
      </Text>
    </View>
  );
}
