import React from "react";
import { View, ScrollView, Image, Dimensions } from "react-native";
import { Text } from "react-native-paper";

export default function LongText({ data }) {
  return (
    <ScrollView>
      <View style={{ flex: 1, margin: 10 }}>
        {data.map((item, index) => {
          if (item.type === "image") {
            return (
              <Image
                key={index}
                source={{ uri: item.source }}
                style={{
                  flex: 1,
                  height: 250,
                  resizeMode: "cover",
                  marginBottom: 10
                }}
              />
            );
          }

          if (item.type === "text") {
            return <Text key={index}>{item.text}</Text>;
          }
        })}
      </View>
    </ScrollView>
  );
}
