import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import { Link } from "react-router-dom";
import { Subscribe } from "unstated";
import { css } from "styled-components";
import gql from "graphql-tag";
import { Dimensions, View, TouchableOpacity } from "react-native";
import { useMutation } from "react-apollo";
import { TextInputMask } from "react-native-masked-text";
import * as Icon from "@expo/vector-icons";
import cotizaImg from "../../assets/cotiza.jpg";
import LoginContainer from "../screens/Login/LoginContainer";
import { isMobileWeb } from "../utils";
import { TextInput, HelperText } from "react-native-paper";

const SET_QUOTE_MUTATION = gql`
  mutation setQuote($user: UserClientInput) {
    setQuote(user: $user) {
      fullName
      email
      accountType
      quote
    }
  }
`;

const ModalRegister = React.forwardRef((props, ref) => {
  const [setQuote, { data, loading }] = useMutation(SET_QUOTE_MUTATION);
  const [withQuote, setWithQuote] = useState(false);
  const [phone, setPhone] = useState("+569");
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);

  const { handleClose, selectedValue, open, ...other } = props;

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (Boolean(user.quote)) {
      setWithQuote(true);
    }
    if (data !== undefined && "setQuote" in data) {
      localStorage.setItem("user", JSON.stringify(data.setQuote));
      setWithQuote(true);
    }
  }, [data, open]);

  function buttonDisplay(loading, withQuote) {
    if (loading) return "...enviando";

    if (withQuote) {
      return "Enviado";
    } else {
      return "Solicitar cotización";
    }
  }

  return (
    <Subscribe to={[LoginContainer]}>
      {login => {
        let isLoggedIn = Boolean(
          Object.keys(JSON.parse(localStorage.getItem("user"))).length
        );

        {
          /* console.log("isloggedin", isLoggedIn); */
        }

        return (
          <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            {...other}
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <TouchableOpacity onPress={handleClose}>
              <View
                style={{
                  position: "fixed",
                  margin: 16,
                  right: 10,
                  top: 10,
                  zIndex: 99999
                }}
              >
                <Icon.MaterialIcons name="close" color="white" size={33} />
              </View>
            </TouchableOpacity>

            <List>
              <div
                style={{
                  maxWidth: isMobileWeb
                    ? Dimensions.get("window").width - 5
                    : 600
                }}
              >
                <div
                  css={css`
                    background: deepskyblue;
                    height: 80px;
                    top: -8px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <h3
                    css={css`
                      color: white;
                      font-family: arial;
                      font-size: 24px;
                      position: relative;
                      padding: 0 10px;
                    `}
                  >
                    {isLoggedIn
                      ? withQuote
                        ? "Solicitud en proceso"
                        : "Cotiza"
                      : "Regístrate para continuar"}
                  </h3>
                </div>
                <div
                  css={css`
                    background: url(${cotizaImg});
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    height: 160px;
                  `}
                />
                <ul
                  css={css`
                    margin: 19px 30px;
                    color: black;
                    font-family: arial;
                    font-size: 18px;
                    padding: ${isMobileWeb ? "0px" : "inherit"};
                    li {
                      margin-bottom: 5px;
                    }
                  `}
                >
                  {withQuote && (
                    <>
                      <li>Un ejecutivo ya recibió tu solicitud.</li>
                      <li>Nos pondremos en contacto en los próximos días.</li>
                    </>
                  )}

                  {isLoggedIn && !withQuote && (
                    <>
                      <li>Más de 100 videos tutoriales con planificaciones</li>
                      <li>Acceso ilimitado desde pre-kínder a IV medio</li>
                      <li>Opción de servicios presenciales</li>
                    </>
                  )}

                  {!isLoggedIn && !withQuote && (
                    <>
                      <li>Prueba las sesiones gratuitas</li>
                      <li>Videos tutoriales desde pre-kínder a IV medio</li>
                    </>
                  )}
                </ul>
                <div
                  css={css`
                    margin: 19px 50px;
                  `}
                >
                  {isLoggedIn ? (
                    <>
                      {withQuote ? null : (
                        <View>
                          <TextInput
                            // testID="emailLogin"
                            // keyboardType="email-address"
                            label="teléfono"
                            value={phone}
                            onChangeText={setPhone}
                            onBlur={() => {
                              setTouched(true);
                              if (phone.length < 11) {
                                setError(true);
                              }
                            }}
                          />

                          <HelperText
                            type="error"
                            visible={touched && error ? true : false}
                            style={
                              touched && error
                                ? { paddingBottom: 10, minWidth: "100%" }
                                : {
                                    minWidth: "100%",
                                    height: 0,
                                    maxHeight: 10
                                  }
                            }
                          >
                            Campo obligatorio
                          </HelperText>
                        </View>
                      )}
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          minWidth: "100%",
                          minHeight: "45px",
                          fontSize: "16px"
                        }}
                        disabled={withQuote || loading}
                        onClick={async () => {
                          let user = JSON.parse(localStorage.getItem("user"));
                          if (phone.length < 11) {
                            setTouched(true);
                            setError(true);
                            return;
                          }
                          await setQuote({
                            variables: {
                              user: { ...user, phone }
                            }
                          });
                        }}
                      >
                        {buttonDisplay(loading, withQuote)}
                      </Button>
                    </>
                  ) : (
                    <Link to="/Register" style={{ textDecoration: "none" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          minWidth: "100%",
                          minHeight: "45px",
                          fontSize: "16px"
                        }}
                      >
                        Registrarse
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </List>
          </Dialog>
        );
      }}
    </Subscribe>
  );
});

export default ModalRegister;
