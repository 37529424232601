import React from "react";
import { List } from "react-native-paper";
import { withNavigation } from "react-navigation";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import { TouchableOpacity, TouchableHighlight } from "react-native";
import { withNavigationRouter, routeNavigation } from "../../withReactRouter";
import { MaterialIcons } from "@expo/vector-icons";

function AttachedPreview(props) {
  let { steps: items, navigation, isPrint } = props;

  return items.map((item, index) => {
    console.log(item);
    return (
      <TouchableOpacity
        // activeOpacity={0.3}
        // underlayColor="lightGray"
        key={index}
        onPress={() => {
          if (item.icon === "play-arrow") {
            return routeNavigation(props).push("Player", {
              videoUrl: item.data,
              placeholder: item.placeholder
            });
          }

          routeNavigation(props).push({
            pathname: "/Attached",
            state: {
              data: item.data || "",
              type: item.icon,
              isPrint
            }
          });
        }}
      >
        <List.Item
          title={item.title}
          description={item.description}
          left={props => (
            <List.Icon
              icon={
                item.icon === "text-subject"
                  ? ({ size, color }) => (
                      <Icon name="text-subject" color={color} size={size} />
                    )
                  : item.icon
              }
            />
          )}
          right={props => {
            if (["play-arrow", "audiotrack", "image"].includes(item.icon))
              return;

            return (
              <TouchableOpacity
                onPress={() => {
                  window.open(
                    `https://drive.google.com/viewerng/viewer?url=http:${item.data}`
                  );
                }}
                style={{ alignSelf: "center", marginRight: 15 }}
              >
                <MaterialIcons name="print" color="black" size={26} />
              </TouchableOpacity>
            );
          }}
        />
      </TouchableOpacity>
    );
  });
}

export default withNavigationRouter(AttachedPreview);
