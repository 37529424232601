import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Modal from "@material-ui/core/Modal";
import { withLastLocation } from "react-router-last-location";
import { Subscribe } from "unstated";
import { Button } from "@material-ui/core";
import StyleSheet from "react-native-extended-stylesheet";
import { Link } from "react-router-dom";
import { css } from "styled-components";

import logoSmall from "../../assets/logo_small.png";
import logoLong from "../../assets/logo_long.png";
import { isDesktop, isMobileWeb, useIsAdmin } from "../utils";
import { HomeContext } from "../screens/Home.web";
import LoginContainer from "../screens/Login/LoginContainer";
import { withNavigationRouter, routeNavigation } from "../withReactRouter";
import ContactModal from "./ContactModal";
import FaqModal from "./FaqModal";
import TourModal from "./TourModal";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: "100%",
    minHeight: isMobileWeb ? 50 : 93,
  },
  menuButton: {
    marginLeft: 5,
    marginRight: 5,
  },
  title: {
    flexGrow: 1,
  },
}));

function HeaderFix(props) {
  let { lastLocation = {} } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElLogout, setAnchorElLogout] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [currentSurvey, setCurrentSurvey] = useState("");
  const [openContact, setOpenContact] = useState(false);
  const [openFaq, setOpenFaq] = useState(false);
  const isAdmin = useIsAdmin();
  const [openTour, setOpenTour] = useState(false);

  const isDemo = (() => {
    return JSON.parse(localStorage.getItem("user")).accountType === "demo";
  })();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  function handleClickLogout(event) {
    setAnchorElLogout(event.currentTarget);
  }

  function handleCloseLogout() {
    setAnchorElLogout(null);
  }

  function handleOpenModal(surveyType) {
    if (isMobileWeb) {
      return window.open(
        currentSurvey === "inicial"
          ? "https://docs.google.com/forms/d/e/1FAIpQLSf-3OmTSFaApFNuLfPPJflA3fYs_WNYy6Us7AzrgVcOk5DqbQ/viewform?embedded=true"
          : "https://docs.google.com/forms/d/e/1FAIpQLScfXN93V623Hq8sIWsOSuxTBAAnpO0WuVjSjB1xYZV2w31kig/viewform?embedded=true",
        "_self"
      );
    } else {
      setCurrentSurvey(surveyType);
      setToggleModal(!toggleModal);
    }
  }

  function handleCloseModal() {
    setToggleModal(!toggleModal);
  }

  function menuSurveys() {
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleOpenModal("inicial")}>
          Encuesta Inicial
        </MenuItem>
        <MenuItem onClick={() => handleOpenModal("final")}>
          Encuesta Final
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
<MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    );
  }

  const classes = useStyles();

  if (isMobileWeb) {
    return (
      <HomeContext.Consumer>
        {({ isVisit = false } = {}) => (
          <Subscribe to={[LoginContainer]}>
            {(login) => {
              console.log("user", login.state.user);
              return (
                <div className={classes.root}>
                  <FaqModal
                    open={openContact}
                    handleClose={() => setOpenContact(false)}
                    isVisit={isVisit}
                  />
                  <FaqModal
                    open={openFaq}
                    handleClose={() => setOpenFaq(false)}
                    isVisit={isVisit}
                  />
                  <Modal
                    open={toggleModal}
                    onClose={handleCloseModal}
                    css={css`
                      display: flex;
                      height: 100%;
                      align-items: center;
                      justify-content: center;
                    `}
                  >
                    <iframe
                      src={
                        currentSurvey === "inicial"
                          ? "https://docs.google.com/forms/d/e/1FAIpQLSf-3OmTSFaApFNuLfPPJflA3fYs_WNYy6Us7AzrgVcOk5DqbQ/viewform?embedded=true"
                          : "https://docs.google.com/forms/d/e/1FAIpQLScfXN93V623Hq8sIWsOSuxTBAAnpO0WuVjSjB1xYZV2w31kig/viewform?embedded=true"
                      }
                      width="700"
                      height="520"
                      frameborder="0"
                      marginheight="0"
                      marginwidth="0"
                    >
                      Cargando...
                    </iframe>
                  </Modal>

                  <AppBar position="static" color="white">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: 50,
                        ...(isDesktop
                          ? {
                              height: 95,
                              maxWidth: StyleSheet.value("$desktop.maxWidth"),
                              transform: "translateX(-50%)",
                              left: "50%",
                              position: "relative",
                            }
                          : {}),
                      }}
                    >
                      {["/Login", "/Register"].includes(
                        (lastLocation && lastLocation.pathname) || ""
                      ) ||
                      ["/Home", "/"].includes(
                        (props.location && props.location.pathname) || ""
                      ) ? (
                        <>
                          <IconButton
                            edge="start"
                            className={classes.menuButton}
                            style={isDesktop ? { transform: "scale(1.4)" } : {}}
                            color="#606060"
                            aria-label="Menu"
                            onClick={handleClick}
                            style={
                              isVisit
                                ? isMobileWeb
                                  ? { display: "none" }
                                  : { visibility: "hidden" }
                                : {}
                            }
                          >
                            <MenuIcon />
                          </IconButton>

                          {menuSurveys()}
                        </>
                      ) : (
                        <IconButton
                          edge="start"
                          className={classes.menuButton}
                          style={isDesktop ? { transform: "scale(1.4)" } : {}}
                          color="#606060"
                          aria-label="Go Back"
                          onClick={routeNavigation(props).goBack}
                        >
                          <ArrowBack />
                        </IconButton>
                      )}
                      <Link
                        to="/Home"
                        style={{
                          textDecoration: "none",
                          left: 5,
                          position: "relative",
                          ...(isDesktop
                            ? {
                                position: "fixed",
                                left: "calc(50% - 75px)",
                                transform: [{ translateX: "-50%" }],
                              }
                            : null),
                        }}
                      >
                        <img
                          style={{
                            width: 40,
                            height: 40,
                            resizeMode: "contain",

                            ...(isDesktop
                              ? {
                                  width: "auto",
                                  height: 70,
                                }
                              : {}),
                          }}
                          src={isDesktop ? logoLong : logoSmall}
                        />
                      </Link>
                      {isVisit ? (
                        <Link to="/Login" style={{ textDecoration: "none" }}>
                          <Button
                            variant="outlined"
                            color="inherit"
                            style={{
                              marginRight: isMobileWeb ? "5px" : "20px",
                            }}
                          >
                            Iniciar sesión
                          </Button>
                        </Link>
                      ) : (
                        <IconButton
                          edge="end"
                          className={classes.menuButton}
                          style={isDesktop ? { transform: "scale(1.4)" } : {}}
                          color="#606060"
                          aria-label="Menu"
                          onClick={handleClickLogout}
                        >
                          <AccountCircle />
                        </IconButton>
                      )}
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorElLogout}
                        keepMounted
                        open={Boolean(anchorElLogout)}
                        onClose={handleCloseLogout}
                      >
                        <MenuItem
                          onClick={() => {
                            login.logout(routeNavigation(props).push);
                          }}
                        >
                          Cerrar sesión
                        </MenuItem>
                        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                      </Menu>
                    </div>
                  </AppBar>
                </div>
              );
            }}
          </Subscribe>
        )}
      </HomeContext.Consumer>
    );
  }

  //desktop
  return (
    <HomeContext.Consumer>
      {({ isVisit = false } = {}) => (
        <Subscribe to={[LoginContainer]}>
          {(login) => {
            console.log("user", login.state.user);

            return (
              <div className={classes.root}>
                {menuSurveys()}

                <FaqModal
                  open={openFaq}
                  handleClose={() => setOpenFaq(false)}
                />
                <ContactModal
                  open={openContact}
                  handleClose={() => setOpenContact(false)}
                />
                <TourModal
                  open={openTour}
                  handleClose={() => setOpenTour(false)}
                  isVisit={isVisit}
                />

                <Modal
                  open={toggleModal}
                  onClose={handleCloseModal}
                  css={css`
                    display: flex;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  <iframe
                    src={
                      currentSurvey === "inicial"
                        ? "https://docs.google.com/forms/d/e/1FAIpQLSf-3OmTSFaApFNuLfPPJflA3fYs_WNYy6Us7AzrgVcOk5DqbQ/viewform?embedded=true"
                        : "https://docs.google.com/forms/d/e/1FAIpQLScfXN93V623Hq8sIWsOSuxTBAAnpO0WuVjSjB1xYZV2w31kig/viewform?embedded=true"
                    }
                    width="700"
                    height="520"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0"
                  >
                    Cargando...
                  </iframe>
                </Modal>

                <AppBar position="static" color="white">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: 95,
                      maxWidth: StyleSheet.value("$desktop.maxWidth"),
                      transform: "translateX(-50%)",
                      left: "50%",
                      position: "relative",
                    }}
                  >
                    <Link
                      to="/Home"
                      style={{
                        textDecoration: "none",
                        left: 25,
                        position: "relative",
                        marginRight: "auto",
                      }}
                    >
                      <img
                        style={{
                          width: 40,
                          height: 40,
                          resizeMode: "contain",

                          ...(isDesktop
                            ? {
                                width: "auto",
                                height: 70,
                              }
                            : {}),
                        }}
                        src={logoLong}
                      />
                    </Link>
                    {isVisit ? (
                      <>
                        <Link to="/" style={{ textDecoration: "none" }}>
                          <Button size="large">Inicio</Button>
                        </Link>
                        <Button size="large" onClick={() => setOpenTour(true)}>
                          Tour
                        </Button>

                        {/* <Button size="large" onClick={() => setOpenFaq(true)}>
                          Preguntas Frecuentes
                        </Button> */}

                        <Button
                          size="large"
                          onClick={() =>
                            window.open("http://www.educaswitch.com")
                          }
                        >
                          Acerca de
                        </Button>

                        <Button
                          size="large"
                          style={{ marginRight: 5 }}
                          onClick={() =>
                            window.open(
                              "http://www.educaswitch.com/contacto",
                              "_self"
                            )
                          }
                        >
                          Contacto
                        </Button>
                        <Link to="/Login" style={{ textDecoration: "none" }}>
                          <Button
                            variant="outlined"
                            color="inherit"
                            style={{
                              marginRight: isMobileWeb ? "5px" : "20px",
                            }}
                          >
                            Iniciar sesión
                          </Button>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to="/" style={{ textDecoration: "none" }}>
                          <Button size="large">Inicio</Button>
                        </Link>
                        <Button size="large" onClick={() => setOpenTour(true)}>
                          Tour
                        </Button>

                        <Button size="large" onClick={() => setOpenFaq(true)}>
                          Preguntas Frecuentes
                        </Button>
                        {isDemo ? null : (
                          <Button size="large" onClick={handleClick}>
                            Encuestas
                          </Button>
                        )}
                        <Button
                          size="large"
                          onClick={() =>
                            window.open("http://www.educaswitch.com")
                          }
                        >
                          Acerca de
                        </Button>
                        <Button
                          size="large"
                          onClick={() => setOpenContact(true)}
                        >
                          Contacto
                        </Button>
                        {isAdmin && (
                          <Link to="/admin" style={{ textDecoration: "none" }}>
                            <Button size="large">Admin</Button>
                          </Link>
                        )}
                        <IconButton
                          edge="end"
                          className={classes.menuButton}
                          style={{ transform: "scale(1.4)", marginRight: 15 }}
                          color="#606060"
                          aria-label="Menu"
                          onClick={handleClickLogout}
                        >
                          <AccountCircle />
                        </IconButton>
                      </>
                    )}
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorElLogout}
                      keepMounted
                      open={Boolean(anchorElLogout)}
                      onClose={handleCloseLogout}
                    >
                      <MenuItem
                        onClick={() => {
                          login.logout(routeNavigation(props).push);
                        }}
                      >
                        Cerrar sesión
                      </MenuItem>
                      {/* <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                    </Menu>
                  </div>
                </AppBar>
              </div>
            );
          }}
        </Subscribe>
      )}
    </HomeContext.Consumer>
  );
}

export default withLastLocation(withNavigationRouter(HeaderFix));
