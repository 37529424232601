import React, { Component } from "react";
import { Platform } from "react-native";
// import { CacheManager } from "react-native-expo-image-cache";
import { DefaultPlayer as Video } from "react-html5video";
// import "./VideoPlaceholder/styles.css";
import "react-html5video/dist/styles.css";
import StyleSheet from "react-native-extended-stylesheet";
import { withTheme } from "styled-components/native";

import { isDesktop } from "../utils";
import { withNavigationRouter, routeNavigation } from "../withReactRouter";

class VideoPlaceholder extends Component {
  state = {
    path: ""
  };

  // async imgPath(uri) {
  //   return await CacheManager.get(uri).getPath();
  // }

  async componentWillMount() {
    // debugger;
    // let path = await this.imgPath("http:" + this.props.placeholder);
    // this.setState({ path });
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   return nextProps.videoUrl.url !== this.props.videoUrl.url;
  // }

  videoRef = ref => {
    // console.log("ref", ref.current.clientHeight);
  };

  render() {
    console.log("rops", this.props);
    return (
      <>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: 670,
            background: this.props.theme.color.lightGrey,
            ...(this.props.inPlayer
              ? {
                  display: "none"
                }
              : {})
          }}
        />
        <div
          ref={this.videoRef}
          style={{
            ...(isDesktop
              ? {
                  maxWidth: StyleSheet.value("$desktop.maxWidth") - 250,
                  transform: "translateX(-50%)",
                  left: "50%",
                  position: "relative",
                  padding: 20
                }
              : {}),
            ...(Platform.OS === "web" && this.props.inPlayer
              ? {
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  margin: "0 10px"
                }
              : {})
          }}
        >
          <Video
            // autoPlay
            controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
            poster={"http:" + this.props.placeholder}
            style={{ display: "flex", alignItems: "center" }}
            onCanPlayThrough={() => {
              // Do stuff
            }}
          >
            <source src={this.props.videoUrl.url} type="video/mp4" />
          </Video>
        </div>
      </>
    );
  }
}

export default withTheme(withNavigationRouter(VideoPlaceholder));
