import React from "react";
import { TextInput, HelperText } from "react-native-paper";

export const RegisterField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <>
    <TextInput {...field} {...props} />
    <HelperText
      type="error"
      visible={touched[field.name] && errors[field.name] ? true : false}
      style={
        touched[field.name] && errors[field.name]
          ? { paddingBottom: 10 }
          : {
              height: 0,
              maxHeight: 10
            }
      }
    >
      {errors[field.name]}
    </HelperText>
  </>
);
