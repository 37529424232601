import React from "react";
import styled, { css } from "styled-components/native";
import styledMap, { mapToTheme as theme } from "styled-map";

export const Wrapper = styled.View`
  background: white;
  ${({ theme, isPrint }) =>
    theme.isDesktop(css`
      background: transparent;
      min-width: 100%;
      margin: auto;
    `)}
`;
