import { Container } from "unstated";

export default class PlanningContainer extends Container {
  state = {
    toggle: null,
    current: "planning"
  };

  setToggle = toggle => this.setState({ toggle });
  setCurrent = current => this.setState({ current });
}
