import styled, { css } from "styled-components/native";
import styledMap, { mapToTheme as theme } from "styled-map";

export const AppWrapper = styled.View`
  flex: 1;
  background-color: ${theme("color")};
`;

export const DesktopWidth = styled.View`
  ${({ theme }) => theme.bp.desktop`
    max-width: 1024px; 
  `}
`;

export const WrapperCenter = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => (padding ? "60px 0px" : "0px")};
  ${({ theme }) => theme.bp.desktop`
    transform: scale(1.6);
  `}
`;

export const LoginImage = styled.Image`
  align-self: center;
  margin-bottom: 20;
  width: 160;
  height: 90;
  resize-mode: contain;
`;

export const ParagraphText = styled.Text`
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "26px" : "")};
  text-decoration: ${({ underline }) => (underline ? "underline" : "")};
  font-style: ${({ italic }) => (italic ? "italic" : "")};
  font-weight: ${({ bold }) => (bold ? "bold" : "none")};
  color: ${({ theme }) => theme.color.softBlack};
  ${({ theme, isPrint }) =>
    !isPrint
      ? theme.isDesktop(
          css`
            font-size: ${theme.size.xmedium}px;
            line-height: ${theme.spacing.huge}px;
          `
        )
      : theme.isDesktop(
          css`
            font-size: ${theme.size.small}px;
            line-height: ${theme.spacing.big}px;
            margin-bottom: ${theme.spacing.small}px;
          `
        )}
`;

// TODO: wip not working (up)
// ${({ theme }) =>
// theme.bp.desktop`
//   font-size: ${({ smaller }) =>
//     smaller ? "10px" : `${theme.size.xmedium}px`};

//   line-height: ${({ smaller }) =>
//     smaller ? "10px" : `${theme.spacing.xHuge}px`};
// `}

export const MenuHR = styled.View`
  opacity: ${({ show }) => (show ? 1 : 0)};
  position: absolute;
  top: 0;
  width: 100%;
  height: 7;
  background-color: black;
  ${({ theme }) => theme.bp.desktop`background-color: ${theme.color.softBlack}`}
`;
