import React, { Component } from "react";
import { Platform } from "@unimodules/core";
import { withNavigation } from "react-navigation";
import { withRouter } from "react-router";

function Withh(Component) {
  return class extends React.Component {
    ExtendedComponent =
      Platform.OS === "ios" ? withNavigation(Component) : withRouter(Component);

    componentDidMount() {
      this.setState({ ...this.props });
    }

    render() {
      return <this.ExtendedComponent {...this.props} />;
    }
  };
}

export const getNavigationData = (
  { navigation = false, history = false },
  field
) => {
  if (navigation) {
    return navigation.getParam(field);
  }

  if (history) {
    return history.location.state[field];
  }
};

export const routeNavigation = ({ navigation = false, history = false }) => {
  return navigation || history;
};

export const withNavigationRouter = Component => {
  let WithhEnhanced = Withh(Component);

  return class Enhancer extends WithhEnhanced {
    render() {
      return super.render();
      const elementsTree = super.render();
      // We grab all the children components of the ScrollView
      const { children } = elementsTree.props;
      // And the original props passed to WrappedComponent element
      // const {...rest } = this.props;
      return children;
    }
  };
};
