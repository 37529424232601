import React from "react";
import { Text } from "react-native-paper";
import { View } from "react-native";
import { withTheme } from "styled-components/native";
import { css } from "styled-components";
import Colors from "../../constants/Colors";

function Headline({ h1, theme, isPrint }) {
  return (
    <View
      css={css`
        font-weight: bold;
        min-height: 40px;
        flex: 1;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: ${Colors.lightGrey};
        ${theme.isDesktop(css`
          background: transparent;
          width: calc(100% - 40px);
          min-height: ${!isPrint ? theme.spacing.xxHuge : theme.spacing.huge}px;
          border: 2px solid ${theme.color.primary};
          border-radius: 5px;
          margin: 0 auto;
          margin-top: 20px;
        `)}
      `}
    >
      <Text
        css={`
          font-weight: bold;
          margin: 0 auto;
          ${theme.isDesktop(
            css`
              color: ${theme.color.primary} !important;
              font-size: ${!isPrint ? theme.size.xmedium : theme.size.small}px;
            `
          )}
        `}
      >
        {h1}
      </Text>
    </View>
  );
}

export default withTheme(Headline);
