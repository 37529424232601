import React, { Component } from "react";
import { Text, Dimensions, View } from "react-native";
import TabBar from "./Home/TabBar.web";
import Header from "../components/Header";
import HeaderFix from "../components/HeaderFix.web";
import StyleSheet from "react-native-extended-stylesheet";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router";
import { isNative, analytics } from "../utils";

export const HomeContext = React.createContext();

class Home extends React.Component {
  state = {
    index: 0,
    routes: [
      { key: "first", title: "Período 1" },
      { key: "second", title: "Período 2" },
      { key: "third", title: "Período 3" }
    ]
  };

  componentDidMount() {
    analytics.pageHit("Home");
  }

  componentWillMount() {
    try {
      //TODO: Mobile - modal se sale solo
      if (Object.keys(JSON.parse(localStorage.getItem("user"))).length) {
        // this.props.history.push("/Home");
      }
    } catch (e) {
      console.log("error Home.web ->", e);
    }
  }

  render() {
    // let { accountType } = this.props.history.location.state;

    // let result = this.props.client.readQuery({
    //   query: gql`
    //     query login($email: String, $password: String) {
    //       login(email: $email, password: $password) {
    //         fullName
    //         email
    //         accountType
    //       }
    //     }
    //   `
    // });

    return (
      <HomeContext.Provider value={{ isVisit: this.props.isVisit }}>
        {isNative ? null : <HeaderFix />}
        <Header />
        <TabBar />
      </HomeContext.Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    // width: "$desktop.maxWidth",
    // margin: "auto",
    height: 0
  }
});

export default withRouter(withApollo(Home));
