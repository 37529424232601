import { createStackNavigator, createSwitchNavigator } from "react-navigation";
import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { Platform, Image, View } from "react-native";

import Header from "../components/Header";
import HomeScreen from "../screens/Home";
import PlayerScreen from "../screens/Player";
import Planning from "../screens/Planning";
import Attached from "../screens/Attached";
import { Text } from "react-native-paper";
import logoSmall from "../../assets/logo_small.png";

let [routes, options] = [
  {
    Session: {
      screen: Planning,
      navigationOptions: ({ navigation }) => {
        return {
          headerTitle: "Sesion 1"
        };
      }
    },
    Home: {
      screen: HomeScreen,
      navigationOptions: ({ navigation }) => {
        return {
          headerTitle: () => <Header />,
          headerLeft: (
            <View style={{ paddingLeft: 10 }}>
              <Image
                style={{
                  alignSelf: "center",
                  width: 40,
                  height: 40,
                  resizeMode: "contain"
                }}
                source={logoSmall}
              />
            </View>
          ),
          headerRight: (
            <Ionicons
              name="md-menu"
              onPress={() => navigation.openDrawer()}
              style={{ paddingRight: 10 }}
              size={30}
            />
          )
        };
      }
    },
    Attached: {
      screen: Attached,
      navigationOptions: ({ navigation }) => {
        return {
          headerTitle: "Anexo"
        };
      }
    },
    Player: {
      screen: PlayerScreen,
      navigationOptions: () => {
        return {
          header: null
        };
      }
    }
  },
  {
    initialRouteName: "Home",
    // defaultNavigationOptions: {
    //   gesturesEnabled: false
    // }
    navigationOptions: ({
      navigation: {
        state: { index, routes }
      }
    }) => {
      let currentRoute = routes[index].routeName;

      return {
        tabBarVisible: currentRoute === "Player" ? false : true
      };
    }
  }
];

// export default (Platform.OS === "ios"
//   ? createStackNavigator(routes, options)
//   : createStackNavigator({
//       Home: {
//         screen: createSwitchNavigator(routes, options),
//         navigationOptions: ({ navigation }) => {
//           return {
//             headerTitle: () => <Header />,
//             headerLeft: (
//               <Ionicons
//                 name="md-menu"
//                 onPress={() => navigation.openDrawer()}
//                 style={{ paddingLeft: 10 }}
//                 size={30}
//               />
//             )
//           };
//         }
//       }
//     }));

export default (Platform.OS === "ios"
  ? createStackNavigator(routes, options)
  : createStackNavigator(routes, options));
