import { Text, Dimensions, View } from "react-native";
import TabBar from "./Home/TabBar.web";
import Header from "../components/Header";
import HeaderFix from "../components/HeaderFix.web";
import StyleSheet from "react-native-extended-stylesheet";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router";
import { isNative } from "../utils";
import thumbSexting from "../../assets/thumb_sexting.png";
import thumbCyberbullying from "../../assets/thumb_cyberbullying.png";
import thumbGrooming from "../../assets/thumb_grooming.png";
import thumbCiberadiccion from "../../assets/thumb_ciberadiccion.png";
import iconCe from "../../assets/icon_ce.svg";
import iconAa from "../../assets/icon_aa.svg";
import iconPc from "../../assets/icon_pc.svg";
import iconVs from "../../assets/icon_vs.svg";
export const HomeContext = React.createContext();

import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import FolderOpen from "@material-ui/icons/FolderOpen";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { Modal } from "@material-ui/core";
import { css } from "styled-components";

import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "45px",
    paddingBottom: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: "#eee",
    padding: theme.spacing(6)
  }
}));

const schoolName = {
  "8eIMkAsGcg": "Colegio Royal American School",
  "0PISHHM12O": "Colegio Simón Bolivar"
};

const cards = id => {
  return {
    "8eIMkAsGcg": [
      {
        title: "Autoestima Académica y Motivación Escolar",
        icon: iconAa,
        subTitle: "Sexting",
        thumb: thumbSexting,
        iconBg: "rgba(44,92,164, 0.7)",
        url: "https://player.vimeo.com/video/374454897",
        activity:
          "¿Sabías que una vez que subimos algo a internet, perdemos el control y que compartir imágenes privadas de otros, sin consentimiento, es un delito tecnológico? Por eso los invitamos a reflexionar: ¿Por qué es importante no subir intimidades a internet (fotos, videos, etc.), ¿por qué crees que no se debe compartir imágenes intimas de otras personas?"
      },
      {
        title: "Clima de Convivencia Escolar",
        subTitle: "Cyberbullying",
        icon: iconCe,
        iconBg: "rgba(60,188,196, 0.7)",
        thumb: thumbCyberbullying,
        url: "https://player.vimeo.com/video/374451786",
        activity:
          "¿Sabías que muchos apoderados no saben que sus estudiantes podrían ser víctimas o agresores de ciberbullying? Por eso te invitamos a conversar con las siguientes preguntas: ¿Has sido agredido en las redes sociales?, ¿cómo te has sentido cuando haz observado o haz sido víctima de maltrato por redes sociales?, ¿has molestado a algún compañero por redes sociales?, ¿por qué crees que no hay que agredir a otros? "
      },
      {
        title: "Hábitos de Vida Saludable",
        subTitle: "Ciberadicción",
        icon: iconVs,
        thumb: thumbCiberadiccion,
        iconBg: "rgba(171,196,84, 0.7)",
        url: "https://player.vimeo.com/video/374450999",
        activity:
          "¿Sabías que muchas horas frente a la pantalla pueden generar adicción? Por eso los invitamos a reflexionar con las siguientes preguntas: ¿Qué otras actividades te gustaría realizar que no tengan relación con internet?, ¿qué aprendiste sobre la ciberadicción?, ¿por qué es importante priorizar espacios sin conexión?"
      },
      {
        title: "Participación y Formación Ciudadana",
        subTitle: "Grooming",
        icon: iconPc,
        iconBg: "rgba(4,164,92, 0.7)",
        thumb: thumbGrooming,
        url: "https://player.vimeo.com/video/374452461",
        activity:
          "¿Sabías que muchos estudiantes no le cuenten a un adulto que están siendo víctimas de una riesgo digital por miedo a la reacción de este? Por eso los invitamos a reflexionar: Si te pasa algo, ¿me contarías?, ¿qué puedo hacer para que confíes en mí?, ¿sabes a quien recurrir en caso que yo no esté?, ¿por qué crees que los desconocidos de internet pueden ser peligrosos como los de la calle? "
      }
    ],
    "0PISHHM12O": [
      {
        title: "Autoestima Académica y Motivación Escolar",
        icon: iconAa,
        subTitle: "Sexting",
        thumb: thumbSexting,
        iconBg: "rgba(44,92,164, 0.7)",
        url: "https://player.vimeo.com/video/374192423",
        activity:
          "¿Sabías que una vez que subimos algo a internet, perdemos el control y que compartir imágenes privadas de otros, sin consentimiento, es un delito tecnológico? Por eso los invitamos a reflexionar: ¿Por qué es importante no subir intimidades a internet (fotos, videos, etc.), ¿por qué crees que no se debe compartir imágenes intimas de otras personas?"
      },
      {
        title: "Clima de Convivencia Escolar",
        subTitle: "Cyberbullying",
        icon: iconCe,
        iconBg: "rgba(60,188,196, 0.7)",
        thumb: thumbCyberbullying,
        url: "https://player.vimeo.com/video/374182417",
        activity:
          "¿Sabías que muchos apoderados no saben que sus estudiantes podrían ser víctimas o agresores de ciberbullying? Por eso te invitamos a conversar con las siguientes preguntas: ¿Has sido agredido en las redes sociales?, ¿cómo te has sentido cuando haz observado o haz sido víctima de maltrato por redes sociales?, ¿has molestado a algún compañero por redes sociales?, ¿por qué crees que no hay que agredir a otros? "
      },
      {
        title: "Hábitos de Vida Saludable",
        icon: iconVs,
        subTitle: "Ciberadicción",
        thumb: thumbCiberadiccion,
        iconBg: "rgba(171,196,84, 0.7)",
        url: "https://player.vimeo.com/video/374183750",
        activity:
          "¿Sabías que muchas horas frente a la pantalla pueden generar adicción? Por eso los invitamos a reflexionar con las siguientes preguntas: ¿Qué otras actividades te gustaría realizar que no tengan relación con internet?, ¿qué aprendiste sobre la ciberadicción?, ¿por qué es importante priorizar espacios sin conexión?"
      },
      {
        title: "Participación y Formación Ciudadana",
        subTitle: "Grooming",
        icon: iconPc,
        iconBg: "rgba(4,164,92, 0.7)",
        thumb: thumbGrooming,
        url: "https://player.vimeo.com/video/374188659",
        activity:
          "¿Sabías que muchos estudiantes no le cuenten a un adulto que están siendo víctimas de una riesgo digital por miedo a la reacción de este? Por eso los invitamos a reflexionar: Si te pasa algo, ¿me contarías?, ¿qué puedo hacer para que confíes en mí?, ¿sabes a quien recurrir en caso que yo no esté?, ¿por qué crees que los desconocidos de internet pueden ser peligrosos como los de la calle? "
      }
    ]
  }[id];
};

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function Projects(props) {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);
  const [current, setCurrent] = React.useState(0);
  const [school, setSchool] = React.useState("");
  const [activity, setActivity] = React.useState(false);

  const handleOpen = (index, activity = false) => {
    setCurrent(index);
    setActivity(activity);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    let s = JSON.parse(localStorage.getItem("user_projects")) || {};
    setSchool(s);

    if (!s.code) {
      props.history.push("/proyectos/login");
    }
  }, []);

  const classes = useStyles();
  if (!school) return null;
  return (
    <HomeContext.Provider value={{ isVisit: props.isVisit }}>
      {isNative ? null : <HeaderFix />}

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        {activity ? (
          <Paper
            style={{ padding: 20, width: Dimensions.get("window").width * 0.5 }}
          >
            <Typography variant="h5" component="h3">
              Preguntas
            </Typography>
            <Typography component="p">
              {cards(school.code)[current].activity}
            </Typography>
          </Paper>
        ) : (
          <iframe
            src={cards(school.code)[current].url}
            width={Dimensions.get("window").width * 0.6}
            height={Dimensions.get("window").width * 0.4}
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        )}
      </Modal>
      <React.Fragment>
        <CssBaseline />
        <AppBar position="relative">
          <Toolbar
            css={css`
              max-width: 1280px;
              margin: 0 auto;
              min-width: 1280px;
            `}
          >
            <FolderOpen className={classes.icon} />
            <Typography variant="h6" color="inherit" noWrap>
              Proyectos
            </Typography>
          </Toolbar>
        </AppBar>
        <main>
          {/* Hero unit */}
          <div className={classes.heroContent}>
            <Container maxWidth="md">
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                {`Proyectos ${schoolName[school.code]}`}
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                paragraph
              >
                A continuación les presentamos los videos realizados por los
                estudiantes durante la unidad de Riesgos Digitales los cuales
                fomentan el Desarrollo Personal y Social. A través de estos, se
                busca educar sobre los peligros que ocurren en internet y sus
                consecuencias. Después de cada video, los invitamos a que en
                conjunto, reflexionen en torno a las preguntas que se señalan.
              </Typography>
            </Container>
          </div>
          <Container className={classes.cardGrid} maxWidth="md">
            {/* End hero unit */}
            <Grid container spacing={2}>
              {cards(school.code).map((card, index) => (
                <Grid item key={index} xs={12} sm={3} md={3}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      css={css`
                        background: ${card.iconBg};
                      `}
                      title="Image title"
                    >
                      <img
                        src={card.icon}
                        css={css`
                          max-width: 40px;
                        `}
                      />
                    </CardMedia>
                    <CardContent className={classes.cardContent}>
                      {/* <Typography gutterBottom variant="h6" component="h6">
                        {card.title}
                      </Typography> */}
                      <Typography style={{ fontWeight: "bold" }}>
                        {card.title}
                      </Typography>
                      <Typography>{card.subTitle || "none"}</Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => handleOpen(index)}
                      >
                        Video
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => handleOpen(index, true)}
                      >
                        Preguntas
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </main>
        {/* Footer */}
        <footer className={classes.footer}>
          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            Todo el material dispuesto en esta página web es de uso exclusivo e
            intransferible del propietario del acceso. Se prohíbe su
            distribución a terceros.
          </Typography>
        </footer>
        {/* End footer */}
      </React.Fragment>
    </HomeContext.Provider>
  );
}
export default withRouter(withApollo(Projects));
