import React from "react";
import { View } from "react-native";
import { Headline, Text } from "react-native-paper";
import { withTheme } from "styled-components/native";
import { css } from "styled-components";
import startCase from "lodash.startcase";
import { isMobileWeb } from "../../utils";

const H1H2 = ({ h1, h2, theme, hasCrisis, isPrint }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <View
      css={css`
        padding: ${theme.spacing.normal}px;
        background-color: white;
        ${theme.isDesktop(css`
          padding: ${!isPrint ? theme.spacing.xxHuge : theme.spacing.normal}px
            ${theme.spacing.big}px;
          background: ${theme.color.white};
          border-radius: 6px 6px 0 0;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 0.5px 0.75px;
          margin-bottom: 0px;
          min-width: 100%;
        `)}
      `}
    >
      <Text
        css={css`
          color: ${theme.color.softBlack};
          font-size: ${theme.size.large + "px"};
          ${theme.isDesktop(css`
            font-size: ${!isPrint ? theme.size.xlarge : theme.size.large}px;
            margin-bottom: ${theme.spacing.small}px;
            font-weight: bold;
            color: ${theme.color.softBack} !important;
          `)};
        `}
      >
        {capitalizeFirstLetter(h1)}
      </Text>
      <Text
        css={`
          color: ${theme.color.softGrey} !important;
          ${theme.bp
            .desktop`font-size: ${theme.size.xmedium}px; color: ${theme.color.midGray} !important;`};
        `}
      >
        {"Objetivo: " + h2}
      </Text>
    </View>
  );
};

export default withTheme(H1H2);
