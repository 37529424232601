import React, { Component } from "react";
import { WrapperCenter } from "../components/styled";

export default function({ data }) {
  return (
    <WrapperCenter>
      <audio controls>
        {/* <source src="horse.ogg" type="audio/ogg"> */}
        <source src={"http:" + data} type="audio/mpeg" />
        Tu navegador no soporta reproducción de audio
      </audio>
    </WrapperCenter>
  );
}
