import React, { Component } from "react";
import { Text } from "react-native-paper";
import StyleSheet from "react-native-extended-stylesheet";
import { View, Dimensions } from "react-native";

import VideoPlaceholder from "../VideoPlaceholder";
import H1H2 from "../H1H2/index";
import { Menu } from "../Menu/index";
import { HeadlineIcons, Headline } from "../Headline/index";
import Step from "../Step";
import AttachedPreview from "../AttachedPreview";
import { Wrapper } from "./styles";
// import * as Animatable from "react-native-animatable";

export default class RenderItem extends Component {
  render() {
    let {
      data,
      data: { type },
      setToggle,
      isPrint,
      handleToggle
    } = this.props;

    // if (type === "AttachedPreview") {
    //   debugger;
    // }

    if (type === "Intro") return null;

    let Component = {
      VideoPlaceholder: !isPrint ? <VideoPlaceholder {...data} /> : null,
      H1H2: <H1H2 {...data} isPrint={isPrint} />,
      Menu: (
        <Menu
          setToggle={setToggle}
          {...data}
          handleToggle={handleToggle}
          isPrint={isPrint}
        />
      ),
      Headline: <Headline {...data} isPrint={isPrint} />,
      HeadlineIcons: <HeadlineIcons {...data} isPrint={isPrint} />,
      AttachedPreview: <AttachedPreview {...data} isPrint={isPrint} />,
      Step: <Step {...data} isPrint={isPrint} />,
      Suggestion: <Step {...data} isSuggestion isPrint={isPrint} />
    }[type];

    return <Wrapper>{Component}</Wrapper>;
  }
}
