//gallery funcionando en web
import React from "react";
import {
  ScrollView,
  Image,
  Dimensions,
  View,
  Text,
  TouchableWithoutFeedback
} from "react-native";
import StyleSheet from "react-native-extended-stylesheet";
import { withTheme } from "styled-components/native";
//TODO: hay un gallery de ejempo en expo que funcionaba con scroll elastico
import * as Icon from "@expo/vector-icons";
import { css } from "styled-components";

class Gallery extends React.Component {
  state = {
    currentPage: 1,
    layout: null
  };

  onScrollEnd = ({
    nativeEvent: { contentOffset, layoutMeasurement: viewSize }
  }) => {
    // Divide the horizontal offset by the width of the view to see which page is visible
    let currentPage = Math.floor(contentOffset.x / viewSize.width);
    this.setState({ currentPage: currentPage + 1 });
  };

  get isDesktop() {
    let width = Dimensions.get("window").width;

    if (width <= 1024) {
      return false;
    } else {
      return true;
    }
  }

  scrollToItem = ({ left = false, right = false } = {}) => {
    let { data } = this.props;
    let { currentPage } = this.state;

    // console.log("data", data, "currentPage", currentPage);

    if (currentPage === data.length && left) return;
    if (currentPage === 1 && right) return;

    if (
      (currentPage === 4 || currentPage === 3 || currentPage === 2) &&
      right
    ) {
      this.scrollViewRef.scrollTo({
        y: 0,
        x:
          this.state.layout.x +
          this.state.layout.width * currentPage -
          this.state.layout.width * 2,
        animated: true
      });
    } else {
      this.scrollViewRef.scrollTo({
        y: 0,
        x: left
          ? this.state.layout.x + this.state.layout.width * currentPage
          : this.state.layout.x +
            this.state.layout.width * currentPage -
            this.state.layout.width,
        animated: true
      });
    }

    this.setState({
      currentPage: left
        ? this.state.currentPage + 1
        : this.state.currentPage - 1
    });
  };

  render() {
    let { data, theme } = this.props;
    let { currentPage } = this.state;

    return (
      <View
        ref={ref => (this.wrapperRef = ref)}
        style={{
          flex: 1,
          position: "relative",
          justifyContent: "center",
          backgroundColor: "white",
          maxWidth: StyleSheet.value("$desktop.maxWidth"),
          ...(this.isDesktop
            ? {
                transform: "translateX(-50%)",
                left: "50%"
              }
            : {})
        }}
      >
        <TouchableWithoutFeedback
          onPress={() => this.scrollToItem({ right: true })}
          css={`
            position: absolute;
            opacity: ${currentPage === 1 ? 0 : 1};
            cursor: ${currentPage === 1 ? "default" : "pointer"};
            left: 20px;
            height: 100%;
            width: 20%;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          `}
        >
          <Icon.Entypo name="chevron-left" size={45} color="white" />
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback
          onPress={() => this.scrollToItem({ left: true })}
          css={`
            position: absolute;
            opacity: ${currentPage === 4 ? 0 : 1};
            cursor: ${currentPage === 4 ? "default" : "pointer"};
            right: 20px;
            height: 100%;
            width: 20%;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          `}
        >
          <Icon.Entypo name="chevron-right" size={45} color="white" />
        </TouchableWithoutFeedback>
        <ScrollView
          onLayout={e => {
            this.setState({ layout: e.nativeEvent.layout });
          }}
          ref={ref => (this.scrollViewRef = ref)}
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          style={{
            margin: 10,
            backgroundColor: "white",
            maxHeight: this.isDesktop ? 500 : 300,
            minHeight: this.isDesktop ? 500 : 300,
            position: "relative"
          }}
          onMomentumScrollEnd={this.onScrollEnd}
        >
          {data.length === 0
            ? []
            : data.map(({ uri, description }, index) => {
                return (
                  <View style={{ position: "relative" }}>
                    <Image
                      key={index}
                      source={{ uri }}
                      style={{
                        maxWidth: StyleSheet.value("$desktop.maxWidth") - 20,
                        minWidth: StyleSheet.value("$desktop.maxWidth") - 20,
                        minHeight: this.isDesktop ? 500 : 300,
                        resizeMode: "cover"
                      }}
                    />
                    {Boolean(description) ? (
                      <Text
                        css={`
                          position: absolute;
                          bottom: 0;
                          background: ${theme.color.softWhite};
                          min-width: 100%;
                          padding: 19px;
                          ${theme.bp
                            .desktop`font-size: ${theme.size.xmedium}px;`}
                        `}
                      >
                        {description}
                      </Text>
                    ) : null}
                  </View>
                );
              })}
        </ScrollView>

        <View
          style={{
            width: "100%",
            justifyContent: "center",
            flexDirection: "row"
          }}
        >
          {data.map((item, index) => (
            <View
              key={index}
              style={{
                width: 8,
                height: 8,
                margin: 2,
                borderRadius: 4,
                backgroundColor:
                  this.state.currentPage === index + 1 ? "black" : "#eaeaea"
              }}
            />
          ))}
        </View>
      </View>
    );
  }
}

export default withTheme(Gallery);
