import * as React from "react";
import { View, Platform } from "react-native";

import ShortText from "../../components/ShortText/ShortText";
import Gallery from "../../components/Gallery/Gallery";
import AudioPlayer from "../../components/AudioPlayer";
import LongText from "../../components/LongText/LongText";
import PdfViewer from "../../components/PdfViewer";
import { getNavigationData } from "../../withReactRouter";
import HeaderFix from "../../components/HeaderFix.web";
import { isNative } from "../../utils";

export default class Attached extends React.Component {
  render() {
    const type = getNavigationData(this.props, "type");
    const data = getNavigationData(this.props, "data");

    return (
      <>
        {isNative || this.props.location.state.isPrint ? null : <HeaderFix />}
        <View style={{ flex: 1 }}>
          {
            {
              uri: <Text>hey ho!</Text>,
              image: <Gallery data={data} />,
              audiotrack: <AudioPlayer data={data} />,
              "short-text": <ShortText data={data} />,
              "text-subject": <LongText data={data} />,
              "picture-as-pdf": (
                <PdfViewer
                  data={data}
                  isPrint={this.props.location.state.isPrint}
                />
              )
            }[type]
          }
        </View>
      </>
    );
  }
}
