//new
import React, { useState, useEffect } from "react";
import { TouchableWithoutFeedback, Text } from "react-native";
import { View, ImageBackground } from "react-native";
import { Card as CardPaper, Title, Paragraph } from "react-native-paper";
import * as Icon from "@expo/vector-icons";
import { withTheme } from "styled-components/native";
import StyleSheet from "react-native-extended-stylesheet";
import Modal from "@material-ui/core/Modal";
//TODO: no funciona en web
// import { Imaie as ImageCache } from "react-native-expo-image-cache";
import { css } from "styled-components";
import { Subscribe } from "unstated";

import { withNavigationRouter, routeNavigation } from "../../withReactRouter";
import Image from "./Image";
import { HomeContext } from "../../screens/Home.web";
import ModalRegister from "../ModalRegister";
import LoginContainer from "../../screens/Login/LoginContainer";
import { preview, isMobileWeb } from "../../utils";
import imageCrisis from "../../../assets/crisis2.png";

function Card(props) {
  let {
    data = {},
    data: { titulo = "", index = "", key = "" } = {},
    theme,
    isSurvey,
    period,
    stage,
    level,
    isIntro = false,
    hasIntro = false,
    isSoon = false,
    isCrisis = false,
    hasCrisis = false,
    hadCrisis = false,
    isBlocked = () => false,
  } = props;

  const [toggleModal, setToggleModal] = useState(false);
  const [open, setOpen] = useState(false);

  function surveyUrl() {
    const surveyMap = {
      1: {
        1: {
          1: "https://docs.google.com/forms/d/e/1FAIpQLSfV9JkgPqfvyIrxayBq6l_aS8Ny5n4O9WqoZ3N1zFpkM9wqVw/viewform?usp=sf_link",
          2: "https://docs.google.com/forms/d/e/1FAIpQLScwUbSnEv_dQi9Bpx1vI1DYGTeAidSwD1iwjZf9wEW_DHGR7Q/viewform?usp=sf_link",
          3: "https://docs.google.com/forms/d/e/1FAIpQLScST-IdXk7KRAPp5qtBT8ThFoPoOUImU9dbVkrPmpM5jQh7eA/viewform?usp=sf_link",
          4: "https://docs.google.com/forms/d/e/1FAIpQLSetVaHnAxDHyunjslJpnGPNf1XIdyrMAprXmZ8S3j2FBmZFaQ/viewform?usp=sf_link",
        },
        2: {
          1: "https://docs.google.com/forms/d/e/1FAIpQLScuNdWlL1qcI3Pez3CHXF3O6up7drlMDS8cff4Hc8NLt_MVwg/viewform?usp=sf_link",
          2: "https://docs.google.com/forms/d/e/1FAIpQLScjMRH07ioUBQicHaLitnteWXrLBueb2bZ7GByxVBEVGoQbPA/viewform?usp=sf_link",
          3: "https://docs.google.com/forms/d/e/1FAIpQLSfjAQPNT6zR2bWM5Yx1ZgTarsSqnxXVM1egEk4uLisjgZ6UTg/viewform?usp=sf_link",
          4: "https://docs.google.com/forms/d/e/1FAIpQLSf07Gwx638pNfA8oPPjNysjRQV0TPRT1ktP56CMVao3x_Y_CA/viewform?usp=sf_link",
        },
        3: {
          1: "https://docs.google.com/forms/d/e/1FAIpQLSef6ls56uyyK5R0mELxcXcvsrNJQbu3I2X8CxuKnGzcE9pD5A/viewform?usp=sf_link",
          2: "https://docs.google.com/forms/d/e/1FAIpQLSfxYPLkgtZYv4f_ynPrdy7o1qMi0kDW6roUmCJwiTo2Ikc_SA/viewform?usp=sf_link",
          3: "https://docs.google.com/forms/d/e/1FAIpQLSc-sFlJEc0SScUwXk7fIYtMARUYQJZcI-O3_XkEEhA8D_a1PQ/viewform?usp=sf_link",
          4: "https://docs.google.com/forms/d/e/1FAIpQLScHBmAU5h9mTGYchuqAFuSuigMcof72BXT0H1Nf1Klq-8vdcg/viewform?usp=sf_link",
        },
      },

      2: {
        1: {
          1: "https://docs.google.com/forms/d/e/1FAIpQLSeYmot87dTRQYVYSCgOaKP68mu_P-ZRQk07pepHx-9VL-vR0A/viewform?usp=sf_link",
          2: "https://docs.google.com/forms/d/e/1FAIpQLSd-bqmrFqOKjACKERk9pLjEKagZwJ9vZE2fohQFwer48Antgg/viewform?usp=sf_link",
          3: "https://docs.google.com/forms/d/e/1FAIpQLSex2zYa01-IYCrOzlV61uBjMq_ReuliLiaPobVYzyZELNxarA/viewform?usp=sf_link",
          4: "https://docs.google.com/forms/d/e/1FAIpQLSe6V98R83NZCEju5hawYLpyzxwXX8GXeiwMW_KrNnpxZSOtmQ/viewform?usp=sf_link",
        },
        2: {
          1: "https://docs.google.com/forms/d/e/1FAIpQLSdrEgIm-NohWXprucqFqxxAjvReScnwuwf8lwmVbq5_zFvD-A/viewform?usp=sf_link",
          2: "https://docs.google.com/forms/d/e/1FAIpQLSdksqbaWjfdS0k2HqbJTtzhZrA4WSuQ0HV3feU5aPFGfSN1qw/viewform?usp=sf_link",
          3: "https://docs.google.com/forms/d/e/1FAIpQLSeyxbXEcMPhozR3hX9qPTd1UVzwKeJ0yWl4V23qiK878dtsug/viewform?usp=sf_link",
          4: "https://docs.google.com/forms/d/e/1FAIpQLSevrPfIvpns0U-E35AGXTJr2ZX5cmy0gI0o3wpxnYB4s6lIIg/viewform?usp=sf_link",
        },
        3: {
          1: "https://docs.google.com/forms/d/e/1FAIpQLSe7H3HXdYQsohzqDwBAN-eWAUoNhzmb2Qo66Ff9EMYatpKMiw/viewform?usp=sf_link",
          2: "https://docs.google.com/forms/d/e/1FAIpQLSc50N6GcfRcqZihdQ85RgTvF-lSBZFl8O6Rs6hhqOC4PW6GFA/viewform?usp=sf_link",
          3: "https://docs.google.com/forms/d/e/1FAIpQLSeqEtpYDMxGyWOLyqpkJ9JHS6pi28Llk_Ibwsq0v1iWx4TNjg/viewform?usp=sf_link",
          4: "https://docs.google.com/forms/d/e/1FAIpQLSdVX2D90PfA4vd3jjkyKuGCOQJE-cC6In3wPVc-D3KAh8f10g/viewform?usp=sf_link",
        },
      },
      3: {
        1: {
          1: "https://docs.google.com/forms/d/e/1FAIpQLSdE6X7reYDCZEa3t4t9eDa08fQk7m5bj6xeHMs4KTM_xPzjvg/viewform?usp=sf_link",
          2: "https://docs.google.com/forms/d/e/1FAIpQLSdiH4zGYMaDyV9i_62JWSeDpIaGp3tsvyj8WoRrT_dVlZfdtw/viewform?usp=sf_link",
          3: "https://docs.google.com/forms/d/e/1FAIpQLSdiI83pi-tAe_j8UkxJTt4L5cG5K87dBJGgYf61LHfBceyFsQ/viewform?usp=sf_link",
          4: "https://docs.google.com/forms/d/e/1FAIpQLScuaYpD8CxsHz2UackBhSo2JHVgu-5hZ5j6ptQYNKMaa61q1Q/viewform?usp=sf_link",
        },
        2: {
          1: "https://docs.google.com/forms/d/e/1FAIpQLSeEE52XKrhXDgw8j67-BkXyycGtdtSorN6_m0gMXIK72Mb1aQ/viewform?usp=sf_link",
          2: "https://docs.google.com/forms/d/e/1FAIpQLSfY1ETqYH7Sr8D99FeTZZAq97kJY7lb9SjZYKcYbKNFzWryYA/viewform?usp=sf_link",
          3: "https://docs.google.com/forms/d/e/1FAIpQLSd-bIYv_dz10A22k4qabsTQjTUUe9QNXaYqdkUL3H42CiY2zw/viewform?usp=sf_link",
          4: "https://docs.google.com/forms/d/e/1FAIpQLSevrPfIvpns0U-E35AGXTJr2ZX5cmy0gI0o3wpxnYB4s6lIIg/viewform?usp=sf_link",
        },
        3: {
          1: "https://docs.google.com/forms/d/e/1FAIpQLSfpM2Uqw5enLNtoM5g-VGDIF6qkY1V4qnmmUpK-IpOh-K-zLQ/viewform?usp=sf_link",
          2: "https://docs.google.com/forms/d/e/1FAIpQLSdy6EKMLUuxIdG31ZCsF5Zq2w8mYt6S9ccWS7wiLpJox46PIA/viewform?usp=sf_link",
          3: "https://docs.google.com/forms/d/e/1FAIpQLSdffKoDoDSE0uDjEwwmTsJdf1IADbH7XE2OroVxVoWXS73Itw/viewform?usp=sf_link",
          4: "https://docs.google.com/forms/d/e/1FAIpQLSfCATO5_0ZFRmrnLQ8zk648eyGwcpj-yeuPmRIQydnFQPENGA/viewform?usp=sf_link",
        },
      },
    };

    if (hasCrisis) {
      return surveyMap[level][period][stage];
    }

    if (hadCrisis) {
      return surveyMap[level][period][stage - 1];
    }
    return surveyMap[level][period][stage];
  }

  function handleOpenModal(isVisit = false) {
    if (isVisit) return setOpen(true);

    if (isMobileWeb) {
      return window.open(surveyUrl(), "_self");
    } else {
      setToggleModal(!toggleModal);
    }
  }

  function handleCloseModal() {
    setToggleModal(!toggleModal);
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  let style = {
    content: { paddingLeft: 10, paddingBottom: 5 },
    title: { fontSize: 15, height: 25 },
    cover: {
      position: "relative",
      maxHeight: 140,
      height: 140,
      minWidth: "100%",
    },
  };

  const maxWidth = StyleSheet.value("$desktop.maxWidth");

  const isDemo = (() => {
    return JSON.parse(localStorage.getItem("user")).accountType === "demo";
  })();

  function title() {
    if (isIntro && index === 0) {
      return `Introducción unidad`;
    } else if (hasIntro && index !== 0) {
      return `Sesión ${index}`;
    } else {
      return `Sesión ${index + 1}`;
    }
  }

  function lock({ index, isVisit }) {
    if (hasCrisis) return null;
    if (hasIntro && index === 1) return null;

    if (isVisit && !isSoon) return null;

    if (isDemo || isBlocked() || isSoon) {
      return (
        <View
          css={css`
            background: rgba(0, 0, 0, 0.4);
            flex: 1;
            align-self: center;
            justify-content: center;
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            display: ${(Object.keys(JSON.parse(localStorage.getItem("user")))
              .length &&
              index !== 0) ||
            isSoon ||
            isBlocked()
              ? "inherit"
              : "none"};
          `}
        >
          <View
            css={css`
              margin: 0 auto;
            `}
          >
            {!isVisit &&
            ((isSoon && !isDemo && !isVisit) || (isBlocked() && !isDemo)) ? (
              <Text
                css={css`
                  color: white;
                  font-weight: bold;
                `}
              >
                Pronto
              </Text>
            ) : (
              <Icon.Feather name="lock" size={28} color="white" />
            )}
          </View>
        </View>
      );
    }
  }

  return (
    <Subscribe to={[LoginContainer]}>
      {(login) => {
        if (isSurvey && isCrisis) return null;

        return (
          <HomeContext.Consumer>
            {({ isVisit = false } = {}) => {
              if ((isVisit && isIntro) || (isDemo && isIntro)) return null;

              return (
                <View
                  css={css`
                    margin-left: 5px;
                    margin-top: 0px;
                    margin-bottom: 20px;
                    min-width: 230px;
                    max-width: 230px;
                    max-height: 200px;
                    ${theme.bp.desktop(css`
                      max-width: ${maxWidth / 5 - 14}px;
                      min-width: ${maxWidth / 5 - 14}px;
                      margin-right: 0px;
                      &:first-child {
                        margin-left: 0px;
                      }
                    `)}
                  `}
                >
                  <ModalRegister
                    open={open}
                    handleClose={() => setOpen(false)}
                  />
                  <CardPaper
                    onPress={() => {
                      if (hasCrisis) {
                        return routeNavigation(props).push({
                          pathname: "/Session",
                          state: {
                            data: {
                              ...data,
                              isIntro,
                              isCrisis,
                              hasCrisis,
                              stage: props.stage,
                              period: props.period,
                              level: props.level,
                            },
                          },
                        });
                      }
                      if (Boolean(isSoon)) {
                        if (!isVisit && !isDemo) return;
                        return setOpen(true);
                      }

                      if ((isSoon && isDemo) || (isBlocked() && !isVisit))
                        return;

                      if (Boolean(isVisit)) {
                        return setOpen(true);
                      }

                      if (!isDemo) {
                        return isSurvey
                          ? null
                          : routeNavigation(props).push({
                              pathname: "/Session",
                              state: {
                                data: { ...data, isIntro, isCrisis, hasCrisis },
                              },
                            });
                      }

                      let newIndex = index;

                      if (isDemo && hasIntro) {
                        newIndex = index - 1;
                      }

                      if (
                        Boolean(
                          Object.keys(JSON.parse(localStorage.getItem("user")))
                            .length
                        ) &&
                        newIndex !== 0
                      ) {
                        return setOpen(true);
                      }

                      return isSurvey
                        ? null
                        : routeNavigation(props).push("Session", {
                            data: { ...data },
                          });
                    }}
                    key={index}
                  >
                    {lock({ index, isVisit })}
                    {/* LOCK END */}
                    {data.caratulaVideo || isCrisis ? (
                      <>
                        {isCrisis ? (
                          <ImageBackground
                            style={style.cover}
                            {...{ preview }}
                            // uri={backgrounds[index + 1]}
                            uri={imageCrisis}
                            source={imageCrisis}
                          >
                            <View
                              style={{
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                transform: [
                                  { translateX: "-50%" },
                                  { translateY: "-50%" },
                                ],
                              }}
                            >
                              <Icon.MaterialCommunityIcons
                                name="alert"
                                color="white"
                                size={33}
                              />
                            </View>
                          </ImageBackground>
                        ) : (
                          <Image
                            style={style.cover}
                            {...{
                              preview,
                              uri:
                                "https:" + data.caratulaVideo.fields.file.url,
                            }}
                            source={{
                              uri:
                                "https:" + data.caratulaVideo.fields.file.url,
                            }}
                          />
                        )}

                        <CardPaper.Content style={[style.content]}>
                          <Title style={style.title}>{title()}</Title>
                          <Text numberOfLines={1}>
                            <Paragraph>
                              {capitalizeFirstLetter(titulo)}
                            </Paragraph>
                          </Text>
                        </CardPaper.Content>
                      </>
                    ) : isSurvey ? (
                      <>
                        <TouchableWithoutFeedback
                          onPress={() => handleOpenModal(isVisit)}
                        >
                          <View style={{ position: "relative" }}>
                            <ImageBackground
                              style={style.cover}
                              {...{ preview }}
                              // uri={backgrounds[index + 1]}
                              uri={"https://i.imgur.com/wLtPNFj.png"}
                              source={{
                                uri: "https://i.imgur.com/wLtPNFj.png",
                              }}
                            >
                              <View
                                style={{
                                  position: "absolute",
                                  left: "50%",
                                  top: "50%",
                                  transform: [
                                    { translateX: "-50%" },
                                    { translateY: "-50%" },
                                  ],
                                }}
                              >
                                <Icon.MaterialIcons
                                  name="question-answer"
                                  color="white"
                                  size={33}
                                />
                              </View>
                            </ImageBackground>
                            <CardPaper.Content style={style.content}>
                              <Title
                                style={style.title}
                              >{`Encuesta unidad`}</Title>
                              <Text numberOfLines={1}>
                                <Paragraph>Medición de resultados</Paragraph>
                              </Text>
                            </CardPaper.Content>
                          </View>
                        </TouchableWithoutFeedback>

                        <Modal
                          open={toggleModal}
                          onClose={handleCloseModal}
                          css={css`
                            display: flex;
                            height: 100%;
                            align-items: center;
                            justify-content: center;
                          `}
                        >
                          <iframe
                            src={surveyUrl()}
                            width="700"
                            height="520"
                            frameborder="0"
                            marginheight="0"
                            marginwidth="0"
                          >
                            Cargando...
                          </iframe>
                        </Modal>
                      </>
                    ) : (
                      <View style={{ position: "relative" }}>
                        <ImageBackground
                          style={style.cover}
                          {...{ preview }}
                          // uri={backgrounds[index + 1]}
                          uri={"https://i.imgur.com/ya6Jdo1.png"}
                          source={{ uri: "https://i.imgur.com/ya6Jdo1.png" }}
                        >
                          <View
                            style={{
                              position: "absolute",
                              left: "50%",
                              top: "50%",
                              transform: [
                                { translateX: "-50%" },
                                { translateY: "-50%" },
                              ],
                            }}
                          >
                            <Icon.MaterialIcons
                              name="people"
                              color="white"
                              size={33}
                            />
                          </View>
                        </ImageBackground>
                        <CardPaper.Content style={style.content}>
                          <Title style={style.title}>
                            {hasCrisis
                              ? "Tips Apoderados"
                              : `Sesión Apoderados`}
                          </Title>
                          <Text numberOfLines={1}>
                            <Paragraph>
                              {hasCrisis
                                ? "Regulación desde el hogar"
                                : "Detalle y anexos para sesión"}
                            </Paragraph>
                          </Text>
                        </CardPaper.Content>
                      </View>
                    )}
                  </CardPaper>
                </View>
              );
            }}
          </HomeContext.Consumer>
        );
      }}
    </Subscribe>
  );
}

export default withTheme(withNavigationRouter(Card));
