import * as React from "react";
import { StyleSheet, View, Platform, Dimensions } from "react-native";
import { Snackbar, Text } from "react-native-paper";

export default class Notification extends React.Component {
  state = {
    visible: false,
    msg: null
  };

  showNotification = (msg = null) => {
    this.setState(state => ({ visible: !state.visible, msg }));
  };

  get isDesktop() {
    let width = Dimensions.get("window").width;

    if (width <= 1024) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { visible } = this.state;

    return Platform.OS === "ios" ? (
      <Snackbar
        visible={visible}
        onDismiss={() => this.setState({ visible: false })}
        style={{
          backgroundColor: "#F31431",
          width: "100%"
        }}
        duration={3000}
      >
        <Text style={{ color: "white" }}>{this.props.children}</Text>
      </Snackbar>
    ) : (
      <View
        style={{
          width: "calc(100% - 16px)",
          position: "fixed",
          left: 0,
          ...(this.isDesktop
            ? {
                top: 0
              }
            : {
                bottom: 0
              })
        }}
      >
        <Snackbar
          visible={visible}
          onDismiss={() => this.setState({ visible: false })}
          style={{
            backgroundColor: "#F31431",
            minWidth: "calc(100% - 16px)",
            position: "fixed",
            left: 0,
            ...(this.isDesktop
              ? {
                  top: 0
                }
              : {
                  bottom: 0
                })
          }}
          duration={3000}
        >
          <Text style={{ color: "white" }}>
            {Boolean(this.state.msg) ? this.state.msg : this.props.children}
          </Text>
        </Snackbar>
      </View>
    );
  }
}
