import React from "react";
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  TouchableWithoutFeedback,
  Platform
} from "react-native";
import { Video, ScreenOrientation } from "expo";
import VideoPlayer from "expo-video-player";
import { MaterialIcons as Icon } from "@expo/vector-icons";
// import { withNavigation } from "react-navigation";
import { withNavigationRouter, getNavigationData } from "../../withReactRouter";
import VideoPlaceholder from "../../components/VideoPlaceholder";
import HeaderFix from "../../components/HeaderFix.web";

class Player extends React.Component {
  changeScreenOrientation = type => {
    try {
      ScreenOrientation.allowAsync(
        ScreenOrientation.Orientation[type.toUpperCase()]
      );
    } catch (e) {}
  };

  componentDidMount() {
    this.changeScreenOrientation("landscape");
  }
  componentWillUnmount() {
    this.changeScreenOrientation("portrait");
  }

  render() {
    return Platform.OS === "ios" ? (
      <View style={styles.container}>
        <VideoPlayer
          videoProps={{
            shouldPlay: true,
            resizeMode: Video.RESIZE_MODE_CONTAIN,
            source: {
              uri: "http:" + getNavigationData(this.props, "videoUrl")
              // uri: "http:" + this.props.navigation.getParam(this.props, "videoUrl")
            }
          }}
          showFullscreenButton={true}
          isPortrait={false}
          playFromPositionMillis={0}
        />
      </View>
    ) : (
      <>
        <HeaderFix />
        <VideoPlaceholder
          placeholder={getNavigationData(this.props, "placeholder")}
          videoUrl={{ url: getNavigationData(this.props, "videoUrl") }}
          inPlayer
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#FFF",
    alignItems: "center",
    justifyContent: "center"
  }
});

export default withNavigationRouter(Player);
