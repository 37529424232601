import React from "react";
import {
  createStackNavigator,
  // createDrawerNavigator,
  createSwitchNavigator
} from "react-navigation";

import RegisterScreen from "../screens/Register";
import LoginScreen from "../screens/Login";
import TabNavigation from "../navigation/TabNavigation";

import { Text } from "react-native";

// const HomeStackNavigator = createStackNavigator({
//   HomeTabNavigator: {
//     screen: TabNavigation
//   }
// });

// const AppDrawNavigator = createDrawerNavigator({
//   Home: {
//     screen: HomeStackNavigator
//   }
// });

const SwitchNavigation = createSwitchNavigator(
  {
    Home: {
      screen: TabNavigation
    },
    Login: {
      screen: LoginScreen
    },
    Register: {
      screen: RegisterScreen
    }
  },
  {
    initialRouteName: "Login"
  }
);

export default SwitchNavigation;
