import * as Yup from "yup";

const msg = {
  password: {
    min: "Contraseña debe tener al menos 6 caracteres",
    required: "Campo obigatorio"
  },
  passwordConfirm: {
    oneOf: "Contraseñas no coinciden",
    min: "Contraseña debe tener al menos 6 caracteres",
    required: "Campo obligatorio"
  }
};

export default Yup.object().shape({
  password: Yup.string()
    .max(255)
    .min(6, msg.password.min)
    .required(msg.password.required),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], msg.passwordConfirm.oneOf)
    .min(6, msg.passwordConfirm.min)
    .max(255)
    .required(msg.passwordConfirm.required)
});
